import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'; //https://stackoverflow.com/questions/42173786/react-router-pass-data-when-navigating-programmatically
import Nav from '../../components/Nav/Nav'
import './SearchScreen.css';
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../features/currentUser'
import { Oval, MagnifyingGlass } from 'react-loader-spinner'
import { searchGroupsAlgolia, searchMoreUsersFir, searchPremiumMessagesAlgolia, searchUsers } from '../../services/f';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry" //https://youtu.be/RDxnu8Xt5Rk + https://cedricdelpoux.github.io/react-responsive-masonry/
import { BsBrowserSafari, BsPlusCircle } from 'react-icons/bs'  //https://react-icons.github.io/react-icons
import UserRows, { AlgoliaGroupRows, AlgoliaPMRows, AlgoliaResultsRows } from '../../components/UserRows/UserRows';
import { FiSearch } from 'react-icons/fi'
import Footer from '../../components/Footer'
import { auth } from '../../firebase';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { Helmet } from 'react-helmet';

function SearchScreen() {
    // http://localhost:3000/search?q=sdffsdf
    // http://localhost:3000/search?q=MFbwMsG0SkVnOYTUMZRKeshqBmA2
    const [searchParams, setSearchParams] = useSearchParams();
    let currentUser = useSelector(getCurrentUser).currentUser?.currentUser// ALSO WENNS n user gibt dann wird anderes screen angezeigt
    const navigate = useNavigate()

    const [searchResults, setsearchResults] = useState([]);
    const [newsearchedUsers, setnewsearchedUsers] = useState([]);
    const [lastVisiblesearchuser, setlastVisiblesearchuser] = useState();
    const [lastsearchUser, setlastsearchUser] = useState(false);

    const [searchtext, setsearchtext] = useState("");
    const [notxt, setnotxt] = useState(false);

    const [showMorebtn, setshowMorebtn] = useState(true);

    // for groups and premiummessages algolia search
    const [searchedalgolia, setsearchedalgolia] = useState([]);
    const [extrasearch, setextrasearch] = useState(0);
    const [algoliapage, setalgoliapage] = useState(0);
    const [lastalgoliapage, setlastalgoliapage] = useState(false);


    useEffect(() => {
        // check if user or psot available then query for them and show them
        // get specific   searchParams.get("code")

        let searchtext = searchParams.get("q")
        //console.log(searchtext)
        setlastsearchUser(false)
        setsearchResults([])
        setlastVisiblesearchuser()
        setnewsearchedUsers([])
        setshowMorebtn(true)
        setnotxt(false)

        if (searchtext === null || searchtext === undefined || searchtext === "") {
            //console.log("no search")
            setnotxt(true)
        } else {
            searchUsers(searchtext.toLowerCase()).then((res) => {
                //console.log("searchUsers result", res)
                setsearchResults(res)
            })
        }
    }, [searchParams.get("q")]);

    useEffect(() => {
       //console.log("these r length of searchedposts", searchResults)
        // searchResults.length === 1 ? setlastsearchUser(true) : null
        if (searchResults.length === 1) {
            setlastsearchUser(true)
        }
        const lastitem = searchResults.slice(-1)
        setlastVisiblesearchuser(lastitem[0])
    }, [searchResults]);

    useEffect(() => {
        //console.log("newsearchedposts.length", newsearchedUsers.length)
        newsearchedUsers.length === 1 ? setlastsearchUser(true) : setlastsearchUser(false)
        //searchResults.length > 40 ? setlastsearchUser(true) : null
        if (searchResults.length > 40) {
            setlastsearchUser(true)
        }
        setsearchResults([...searchResults.slice(0, -1), ...newsearchedUsers])
    }, [newsearchedUsers]);

    const searchExtraFunction = (option) => {
        let searchtext = searchParams.get("q")
        setsearchedalgolia([])
        setextrasearch(option)
        setalgoliapage(0)
        setlastalgoliapage(false)

        if (option === 1) {
            searchPremiumMessagesAlgolia(searchtext.toLowerCase(), currentUser, 0).then((pms) => {
                //console.log("searchPremiumMessagesAlgolia", pms)
                if (pms.length === 0) {
                    setsearchedalgolia(["nothing"])
                    setlastalgoliapage(true)
                    return
                } else {
                    setsearchedalgolia(pms)
                }
            })

        } else if (option === 2) {
            searchGroupsAlgolia(searchtext.toLowerCase(), currentUser, 0).then((groups) => {
                //console.log("found groups", groups)
                if (groups.length === 0) {
                    setsearchedalgolia(["nothing"])
                    setlastalgoliapage(true)
                    return
                } else {
                    setsearchedalgolia(groups)
                }
            })
        }
    }

    const searchMoreAlgolia = () => {
        let searchtext = searchParams.get("q")
       // console.log("search searchMoreAlgolia", extrasearch, searchtext)
        if (extrasearch === 1) {
            searchPremiumMessagesAlgolia(searchtext.toLowerCase(), currentUser, algoliapage + 1).then((pms) => {
                //console.log("searchPremiumMessagesAlgolia", pms.length)
                if (pms.length === 0) {
                    setlastalgoliapage(true)
                    return
                } else {
                    setsearchedalgolia([...searchedalgolia, pms])
                }
            })

        } else if (extrasearch === 2) {
            searchGroupsAlgolia(searchtext.toLowerCase(), currentUser, algoliapage + 1).then((groups) => {
                //console.log("found more groups", groups.length)
                setalgoliapage(algoliapage + 1)
                if (groups.length === 0) {
                    setlastalgoliapage(true)
                    return
                } else {
                    setsearchedalgolia([...searchedalgolia, groups])
                }
            })
        }
    }

    const searchMoreUsers = () => {
        if (lastVisiblesearchuser === undefined) {
            return
        }
        //console.log(lastVisiblesearchuser)
        if (!lastsearchUser) {
            searchMoreUsersFir(searchParams.get("q").toLowerCase(), lastVisiblesearchuser).then(setnewsearchedUsers)
        }
    }

    return (
        <div className='searchscreen'>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi - Search Users/Groups/Premium Messages❤️" />
                <meta property="og:description" content="Premsi | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/search" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
                <meta property="og:site_name" content="Premsi - sell digital content" />
                <link rel="canonical" href="https://premsi.com/search" />

                <meta name="description" content="Premsi | Search other Premsi Users/Groups/Premium Messages here . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <title>Premsi - Search Users/Groups/Premium Messages</title>
            </Helmet>
            <Nav />
            {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}

            <div style={{ width: "100%", display: "flex", marginTop: 20, flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontWeight: "bold" }}>Or Search for</p>
                <div className='grouppmdiv'>
                    <div onClick={() => searchExtraFunction(1)} style={{ height: 30, cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10, paddingBottom: 10, paddingRight: 20, paddingLeft: 20, backgroundColor: "#009FFD", borderRadius: 20 }}>
                        <p style={{ color: "white" }}>Premium Messages</p>
                    </div>
                    <div onClick={() => searchExtraFunction(2)} style={{ height: 30, cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10, paddingBottom: 10, paddingRight: 20, paddingLeft: 20, backgroundColor: "#009FFD", borderRadius: 20 }}>
                        <p style={{ color: "white" }}>Groups</p>
                    </div>
                </div>
            </div>

            {extrasearch === 0
                ?
                <div className='searchResultsDiv'>
                    {searchResults.length === 0 && notxt === false ?
                        <div>
                            <Oval
                                height={40}
                                width={40}
                                color="#009FFD"
                                wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="white"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                            />
                            <h1 style={{ marginBottom: 3, textAlign: "center", fontSize: 17, color: "black" }}>Loading search results...</h1>
                            <p style={{ textAlign: "center", fontSize: 12 }}>If this takes too long please refresh this window.</p>
                        </div>
                        :
                        (notxt
                            ?
                            <div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "black" }}>
                                    Search through Premsi.com - The easiest way to sell digital content ❤️
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                                    <form onSubmit={(e) => (e.preventDefault(), setextrasearch(0), navigate("/search?q=" + searchtext))} className='searchform_searchscreen' style={{ color: "black" }}>
                                        <input onChange={(e) => setsearchtext(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd" }} type='search' placeholder='Search Users/Groups/Premium Messages' />
                                        <FiSearch onClick={(e) => (e.preventDefault(), setextrasearch(0), navigate("/search?q=" + searchtext))} className='' size={26} color='#009ffd' alt='search Premsis' />
                                    </form>
                                </div>

                            </div>
                            :
                            (searchResults.length === 1 && searchResults[0] === undefined ?
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "black" }}>
                                        Oops! Seems like there are no users for your search... 😢
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                                        <form onSubmit={(e) => (e.preventDefault(), setextrasearch(0), navigate("/search?q=" + searchtext))} className='searchform_searchscreen' style={{ color: "black" }}>
                                            <input onChange={(e) => setsearchtext(e.target.value)} style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd" }} className='searchinput_searchscreen' type='search' placeholder='Search Users/Groups/Premium Messages' />
                                            <FiSearch onClick={(e) => (e.preventDefault(), setextrasearch(0), navigate("/search?q=" + searchtext))} className='' size={26} color='#009ffd' alt='search Premsis' />
                                        </form>
                                    </div>
                                </div>
                                :
                                <div className='searchedusersdiv'>
                                    {searchResults.slice(0, -1).map((userdata) => (
                                        <UserRows user={userdata} />
                                    )
                                    )}
                                    {!lastsearchUser
                                        ?
                                        <div onClick={() => searchMoreUsers()} style={{ cursor: "pointer", display: "flex", marginTop: 20, justifyContent: "center", alignItems: "center" }}>
                                            <BsPlusCircle size={25} color="#009FFD" />
                                        </div>
                                        :
                                        <div style={{ display: "flex", marginTop: 20, justifyContent: "center", alignItems: "center" }}>
                                            <p style={{ fontSize: 13 }}>These are all users 🎉</p>
                                        </div>
                                    }
                                </div>
                            )
                        )
                    }
                </div>
                :
                <div className='searchResultsDiv'>
                    {searchedalgolia.length === 0 && notxt === false ?
                        <div>
                            <Oval
                                height={40}
                                width={40}
                                color="#009FFD"
                                wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="white"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                            />
                            <p style={{ marginBottom: 3, textAlign: "center" }}>Loading search results...</p>
                            <p style={{ textAlign: "center", fontSize: 12 }}>If this takes too long please refresh this window.</p>
                        </div>
                        :
                        (notxt
                            ?
                            <div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "black" }}>
                                    Oops! Seems like you didn't search anything... 😢
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                                    <form onSubmit={(e) => (e.preventDefault(), setextrasearch(0), navigate("/search?q=" + searchtext))} className='searchform_searchscreen' style={{ color: "black" }}>
                                        <input onChange={(e) => setsearchtext(e.target.value)} className='searchinput_searchscreen' type='search' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd" }} placeholder='Search Users/Groups/Premium Messages' />
                                        <FiSearch onClick={(e) => (e.preventDefault(), setextrasearch(0), navigate("/search?q=" + searchtext))} className='' size={26} color='#009ffd' alt='search Premsis' />
                                    </form>
                                </div>

                            </div>
                            :
                            (searchedalgolia.length === 1 && searchedalgolia[0] === "nothing" ?
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "black" }}>
                                        Oops! Seems like there are no {extrasearch === 1 ? "Premium Messages" : "Groups"} for your search... 😢
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                                        <form onSubmit={(e) => (e.preventDefault(), setextrasearch(0), navigate("/search?q=" + searchtext))} className='searchform_searchscreen' style={{ color: "black" }}>
                                            <input onChange={(e) => setsearchtext(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd" }} type='search' placeholder='Search Users/Groups/Premium Messages' />
                                            <FiSearch onClick={(e) => (e.preventDefault(), setextrasearch(0), navigate("/search?q=" + searchtext))} className='' size={26} color='#009ffd' alt='search Premsis' />
                                        </form>
                                    </div>
                                </div>
                                :
                                <div className='searchedusersdiv'>
                                    <p style={{ marginBottom: 20, fontSize: 18, fontWeight: "bold" }}>These are your searched {extrasearch === 1 ? "Premium Messages" : "Groups"} 🔥</p>
                                    {searchedalgolia.map((results) => (
                                        (extrasearch === 1 ? <AlgoliaPMRows result={results} /> : <AlgoliaGroupRows result={results} />)
                                    )
                                    )}
                                    {!lastalgoliapage
                                        ?
                                        <div onClick={() => searchMoreAlgolia()} style={{ cursor: "pointer", display: "flex", marginTop: 20, justifyContent: "center", alignItems: "center" }}>
                                            <BsPlusCircle size={25} color="#009FFD" />
                                        </div>
                                        :
                                        <div style={{ display: "flex", marginTop: 20, justifyContent: "center", alignItems: "center" }}>
                                            <p style={{ fontSize: 13 }}>These are all results 🎉</p>
                                        </div>
                                    }
                                </div>
                            )
                        )
                    }

                    <p style={{ marginTop: 20, fontSize: 14, textAlign: "center" }}>Download our <a href="app" style={{ color: "black" }}>app</a> to purchase and view the premium messages and join the Groups</p>
                </div>
            }

            <Footer />

        </div>
    )
}

export default SearchScreen

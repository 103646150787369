import React, { useState } from 'react'
import Footer from '../../../components/Footer'
import { useNavigate } from 'react-router-dom'
import './index.css'
import { Helmet } from 'react-helmet'
import NavForNotHome from '../../../components/Nav/NavForNotHome';

function TermsOfService() {
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi - Terms of Service❤️" />
                <meta property="og:description" content="Premsi | Terms of Service . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/termsofservice" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
                <meta property="og:site_name" content="Premsi - sell digital content" />
                <link rel="canonical" href="https://premsi.com/termsofservice" />

                <meta name="description" content="Premsi | Terms of Service . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <title>Premsi - Terms of Service</title>
            </Helmet>
            <NavForNotHome />
            <div className='outerlegaldiv' style={{ marginTop: 20 }}>
                <div className='innerlegaldiv'>

                    <h1>Terms of Service - Premsi</h1>
                    <p>By accessing or using the Service provided by PREMSI you agree to be bound by the following terms and conditions. Please read this agreement carefully to ensure you understand each provision. This agreement applies to all individuals, including visitors, users, and any other parties accessing the Service ("Users").</p>
                    <p>This is a legally binding contract between you and PREMSI Accessing or using the Service signifies that you have read, understood, and agreed to be bound by this agreement. If you are not eligible to use the Service or do not agree to the terms and conditions stated in this agreement, you do not have permission to use the Service.</p>

                    <h2>1) Use of Our Service </h2>
                    <p>A. Eligibility. You may use the Service provided by PREMSI, only if you can enter into a legally binding contract with PREMSI and if you comply with this Agreement as well as all applicable local, state, national, and international laws, rules, and regulations. Usage of the Service by individuals under the age of 13 is strictly prohibited and constitutes a violation of this Agreement. The Service is not available to any Users who have been previously banned from using the Service by PREMSI
                    </p>
                    <p>B. PREMSI Service. In accordance with sole the terms and conditions of this Agreement, you are granted a non-exclusive, limited, non-transferable, and revocable license to use the Service as permitted by its features. PREMSI reserves all rights not explicitly granted in this Agreement, including rights to the Service and the content provided by PREMSI (referred to as "PREMSI Content").
                    </p>

                    <h2>2) Accounts  </h2>
                    <p>Your PREMSI account grants you access to the services and features that we may establish and maintain from time to time, at our sole discretion. We may offer different account types for various users. If you create a PREMSI account on behalf of a client, company, organization, or any other entity, then (a) "you" refers to both yourself and that client, company, organization, or entity, and (b) you represent and warrant that: (i) you are an authorized representative of the client, company, organization, or entity, with the power to legally bind them to this Agreement, (ii) you have explicit authorization from the client, company, organization, or entity to create and operate an account on their behalf, and (iii) you agree to this Agreement on behalf of the client, company, organization, or entity. </p>
                    <p>You must not use another User's account without their permission. When creating your account, you must provide accurate and complete information, and you must keep this information up to date. You bear sole responsibility for any activity that occurs on your account, and you must ensure the security of your account password. If your profile is hacked, the burden of proof falls on the hacked users. We recommend using "strong" passwords, including upper and lowercase letters, numbers, and symbols. If you become aware of any security breaches or unauthorized use of your account, you must promptly change your password in our application. PREMSI will not be held liable for any losses resulting from the unauthorized use of your account. </p>
                    <p>You have control over your user profile and your interaction with the Service by adjusting the Settings within the PREMSI Mobile Software, as defined below. By providing your email address to PREMSI, you consent to us using that email address to send you Service-related notifications, including any legally required notices, instead of using postal mail. We may also use your email address to send you other messages, such as updates to Service features and special offers. If you prefer not to receive such email messages, you can opt-out by following the "Unsubscribe" link in the email. Please note that opting out may result in not receiving email messages concerning updates, improvements, or offers. </p>

                    <h2>3) Services</h2>
                    <p>By accessing and using the services provided by PREMSI (referred to as "the Service"), you agree to comply with the following conditions. It is strictly prohibited to engage in any of the activities listed below:
                        1.	Copying, distributing, or disclosing any part of the Service in any medium, including automated or non-automated "scraping."
                        2.	Using any automated system, including "robots," "spiders," "offline readers," etc., to access the Service in a manner that exceeds the request limit a human can reasonably produce within the same timeframe using a conventional online web browser. However, operators of public search engines are granted temporary permission to use spiders to create publicly available searchable indices of materials on PREMSI, excluding caches or archives of such materials.
                        3.	Transmittng spam, chain letters, or any other unsolicited email.
                        4.	Attempting to interfere with or compromise the system integrity or security or decipher any transmissions to or from the servers running the Service.
                        5.	Taking any action that, at our sole discretion, imposes an unreasonable or disproportionately large load on our infrastructure.
                        6.	Upload invalid data, viruses, worms, or other software agents through the Service.
                        7.	Collecting or harvesting any personally identifiable information, including account names, from the Service in a manner that exceeds the request limit a human can reasonably produce within the same timeframe using a conventional online web browser.
                        8.	Using the Service for any commercial solicitation purposes.
                        9.	Interfering with the proper functioning of the Service.
                        10.	Accessing any content on the Service through any technology or means not provided or authorized by the Service.
                        11.	Bypassing any measures, we employ to prevent or restrict access to the Service, including features that limit the use, copying, or access to any content available on the Service. Accessing any audiovisual content on the Service, except through Streaming (as defined below), is strictly prohibited unless explicitly permitted by the Service's functionality. "Streaming" refers to the real-time digital transmission of an audiovisual work from the PREMSI Service to a User's device via the internet. This transmission is intended for immediate viewing and should not be copied, stored, permanently downloaded, or redistributed by the User.
                    </p>

                    <h2>4) User interaction</h2>
                    <p>By using the services provided by PREMSI you acknowledge and agree to be bound by the following terms and conditions regarding your interactions with other users of the PREMSI plattorm ("Users"):
                        1.	User Responsibility: You are solely responsible for your interactions with other Users on the PREMSI plattorm. While PREMSI reserves the right to monitor disputes between you and other Users, we are under no obligation to do so. Therefore, PREMSI shall not be held liable for any disputes, actions, or inactions of other Users.
                        2.	Promotions and Contests: If you choose to use the PREMSI service to communicate or administer a promotion, contest, or sweepstakes ("Promotion"), you agree to assume full responsibility for ensuring its lawful operation. This includes, but is not limited to, complying with the following requirements:
                        (a) Official Rules: You must establish and enforce official rules for the Promotion.
                        (b) Offer Terms and Eligibility: You are responsible for defining the terms and eligibility requirements of the Promotion.
                        (c) Compliance with Regulations: You must adhere to all applicable rules and regulations governing the Promotion and any prizes offered. This includes obtaining any necessary registrations and regulatory approvals in the relevant jurisdiction(s).
                        (d) Compliance with Laws and Guidelines: You must comply with all applicable laws, rules, regulations, and guidelines, including but not limited to the Federal Trade Commission Act and Federal Trade Commission Guidelines Concerning Use of Endorsements and Testimonials in Advertising.
                        (e) Release and Non-Association: All Promotions on the PREMSI plattorm must include the following: (i) a complete release of PREMSI by each entrant or participant, and (ii) an acknowledgment that the Promotion is in no way sponsored, endorsed, administered by, or associated with PREMSI, unless prior written consent has been obtained from an authorized representative of PREMSI
                        (f) Administration and Advice: PREMSI will not help or advice in the administration of your Promotion, including whether consent is required for the use of user content or how to obtain such consent. You agree that if you choose to administer your Promotion using the PREMSI service, you do so at your own risk.
                        By continuing to use the PREMSI service, you acknowledge that you have read, understood, and agreed to abide by these terms and conditions regarding your interactions with other Users and the administration of Promotions on the plattorm.
                    </p>

                    <h2>5) User Content Ownership and License</h2>
                    <p>By accessing and using the services provided by PREMSI you acknowledge and agree to the following terms regarding the ownership and licensing of user-generated content.
                        1.	User Content Ownership: When using certain areas of the Service, you may have the ability to post or provide content, including but not limited to profile information, videos, images, music, comments, questions, and other materials (collectively referred to as "User Content"). It is important to note that PREMSI does not claim ownership rights over any User Content created by you.
                        2.	Your Rights to User Content: As the creator of the User Content, you retain all rights, title, and interest in and to the User Content you create, post, or otherwise make available through the Service. PREMSI does not claim ownership rights over your User Content.
                        3.	License Granted to PREMSI: By posting or making available any User Content on or through the Service, and based on the privacy settings you choose, you hereby grant PREMSI a limited, royalty-free, sublicensable (to PREMSI subsidiaries and affiliated companies), transferable, irrevocable, non- exclusive, worldwide license to use, reproduce, modify, publish, publicly perform, publicly display, list information regarding, translate, distribute, syndicate, create derivative works of, or otherwise use your User Content. This license includes your name, voice, and/or likeness as it is contained within your User Content, in whole or in part, and in any form, media, or technology, whether currently known or developed in the future. The purpose of this license is solely to enable PREMSI to provide the Service. It should be noted that this license does not grant PREMSI the right to sell your User Content to third parties or profit from your User Content in any way unrelated to providing and promoting the Service. Furthermore, this license does not give PREMSI any ownership rights over your User Content, nor does it limit your ability to use your User Content outside of the Service.
                    </p>

                    <h2>6) User Conduct </h2>
                    <p>A. Fair Conduct: Users of the PREMSI Service is required to act fairly, respectfully, and legally. Fair conduct includes, but is not limited to, the following:
                        1.	Treating other Users with respect, and refraining from engaging in discriminatory, harassing, or offensive behavior based on race, ethnicity, gender, religion, sexual orientation, or any other protected characteristic.
                        2.	Respecting the intellectual property rights of others, including copyrights, trademarks, and patents.
                        3.	Providing accurate and truthful information when interacting with other Users or creating User Content.
                        4.	Complying with all applicable laws, rules, and regulations governing the use of the Service.
                        5.	Respecting the privacy of other Users and refraining from disclosing their personal information without their consent.
                        6.	Using the Service in a manner that does not disrupt or interfere with the experience of other Users or the proper functioning of the Service.
                        Limitations of PREMSI Liability
                        While PREMSI strives to provide a safe and enjoyable experience for its Users, there are limitations to its liability. PREMSI shall not be held responsible for:
                        1.	User conduct that violates this Agreement or any applicable laws, rules, or regulations.
                        2.	Any loss or damage incurred as a result of Users' interactions with other Users, including disputes, actions, or inactions.
                        3.	Any infringement of intellectual property rights by Users, including copyright infringement, that PREMSI cannot reasonably avoid or check despite its best efforts.
                        4.	Any unauthorized access to or use of User accounts, unless directly caused by the negligence or intentional misconduct of PREMSI
                    </p>

                    <h2>7) Copyright Protection </h2>
                    <p>
                        PREMSI takes the protection of people's copyrights seriously. While PREMSI endeavors to prevent copyright infringement, it cannot be held responsible for every instance of infringement that may occur on the Service. PREMSI does not assume liability for the following:
                        1.	Copyright infringement by Users that cannot reasonably be avoided or checked by PREMSI despite its best efforts.
                        2.	Infringement resulting from Users' unauthorized use, reproduction, or distribution of copyrighted materials through the Service.
                        3.	Infringement caused by User-generated content that was created, posted, or made available by Users without the necessary rights or permissions.
                    </p>


                    <h2>8) Indemnification  </h2>
                    <p>
                        By using the Service, Users agree to indemnify and hold harmless PREMSI, its officers, directors, employees, agents, and affiliates from any claims, damages, liabilities, losses, or expenses (including legal fees) arising out of or in connection with:
                        1.	User conduct that violates this Agreement or any applicable laws, rules, or regulations.
                        2.	Infringement of intellectual property rights by Users, including copyright infringement.
                        3.	Any dispute, action, or inaction between Users.
                        4.	Any unauthorized access to or use of User accounts, unless directly caused by the negligence or intentional misconduct of PREMSI
                        Users agree to cooperate fully in the defense of any such claims and to not settle any matter without the prior written consent of PREMSI.
                        Please note that by accessing or using the Service, you acknowledge and accept the user conduct rules, the limitations of liability, the copyright protection disclaimer, and the indemnification clause as described in this agreement. Violation of these terms may result in the termination of your access to the Service.
                    </p>


                    <h2>9) Notice of Modification</h2>
                    <p>
                        PREMSI will promptly and clearly notify through the mobile app any modification of this agreement. Continuing to use the service after the notification will be considered an acceptance of the modifications.
                    </p>

                    <h2>10) Notice for California Users </h2>
                    <p>
                        Under California Civil Code Section 1789.3, California users of our Site are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952-5210.
                        By using the Service, you affirm that you have the necessary rights to grant the license to PREMSI for the use of your User Content. Please review our complete Terms of Use for further information on your rights and responsibilities when using the Service.
                    </p>

                    <h2>11) Services with costs</h2>
                    <p>
                        1. Some services of Premsi require the payment of a fee. The scope of the services included in Premsi and usable by the user depends on whether and which services are used free of charge or against payment. In the case of free use, the user has access to certain basic functions and information of the respective services. An extended range of functions is available to the user if the services are activated for the user as part of a subscription. The Premsi provides an overview of the available fee-based services, their fees, scope of services, restrictions and terms in the Premsi. The payment of the fee only entitles the user to access the services available or visible in the offer presentation. All fees mentioned are inclusive of the applicable sales tax.
                    </p>
                    <p>
                        2. The individual steps of purchasing paid services are described in the descriptions within the third party’s App Store, Premsi, or the website www.premsi.com. By placing an order, the user submits an offer. The mere pressing of the order button does not constitute a contract of sale for the paid content. Only after successful payment and the corresponding electronic confirmation, the order is considered accepted and the sales contract is concluded. Acceptance can also be implied, in particular by activating the chargeable services.
                    </p>
                    <p>
                        3. The Premsi offers the option to unlock the entire premium content of the Premsi by a payment. The Premsi offers subscriptions with 1 month duration. With the activation of the paid content, the user receives the right to use it for his own, non-commercial purposes on all devices connected and compatible with his App Store account. Any further use – in particular publication on the Internet, as well as the unauthorized copying, distribution or making available of the contents via the Internet, whether free of charge or against payment – is not permitted. The same applies to transcribing the contents, removing copyright information and source references as well as other editing or manipulation of the download. The granted rights of use are not transferable or sublicensable and are subject to full payment. Uses permitted by copyright law are not restricted.
                        The Provider is entitled to revise, update or otherwise change the content at any time if this should be necessary for technical, legal or content reasons.
                        The Provider shall keep all purchased digital data available for download for a reasonable period of time. However, unlimited data backup and data availability cannot be guaranteed.
                    </p>
                    <p>
                        4. The payment methods shown in the offer are accepted; the payment process is usually operated by appropriate service providers. These may include in particular the payment systems offered by the respective App Store operators. As far as the respective service provider includes its own general terms and conditions of business or terms of use (GTC) in individual cases, these are exclusively valid for the payment process. If applicable, the user must have a user account with the service provider.
                    </p>
                    <p>
                        5. The Provider of the App Store can influence the duration or renewal, or their available paid services. Depending on the App Store, fee-based services may expire at the end of the agreed term without requiring a termination, or the respective contract term may be extended indefinitely or for a corresponding period of time. Details can be found in the terms and conditions or the respective offer presentation of the App Store. The App Store offers the possibility to terminate paid services via a corresponding menu item.
                    </p>
                    <p>
                        6. If fees cannot be collected and if the user is responsible for the event causing the collection, the user shall bear all costs arising therefrom. If payments made are charged back or the user does not pay fees, Premsi is entitled to block all or access to individual services from Premsi. The buyer purchases digital content that is sent and received immediately, hence we don´t offer refunds, if the support is not contacted in under 30mins from the purchase (support@premsi.com).
                    </p>
                    <p>
                        7. The Provider reserves the right to change its business model at any time and, for example, to provide certain or all services only against payment. The respective fees are published in the Premsi. The user is free to terminate the use of the service or to decide to continue using the service for a fee.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TermsOfService

import React, { useCallback, useEffect, useRef, useState } from 'react'
import './CreatePremiumMessageScreen.css'
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { auth, functions } from '../../firebase';
import { Helmet } from 'react-helmet';
import { CiCirclePlus } from "react-icons/ci";
import { createGroup, createPremiumMessageFB, getGroupsByUserId, getPremiumMessagesOfUser, saveMediaToStorage } from '../../services/f';
import { getCurrentUser } from '../../features/currentUser';
import { useDispatch, useSelector } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { Switch } from '@mui/material'
import imageCompression from 'browser-image-compression';
import { Oval } from 'react-loader-spinner';
import { httpsCallable } from 'firebase/functions';
import * as tus from 'tus-js-client'
import uuid from 'uuid-random';
import SignUpScreenNotHome from '../SingupScreenNotHome/SignUpScreenNotHome';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const resizeObserverOptions = {};

const maxWidth = 200;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

function PremiumMessageRow({ premiummessage }) {

    const navigate = useNavigate()
    const [date, setdate] = useState("");
    const [numPages, setNumPages] = useState();
    const [copied, setcopied] = useState(false);

    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();

    console.log("PremiumMessageRow", premiummessage[1])

    const onResize = useCallback((entries) => {
        const [entry] = entries;
        //console.log("resized", entry.contentRect.width)

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        let offset = new Date().getHours() - new Date().getUTCHours()//this after seconds: + offset * 60 * 60 * 1000
        let year = new Date(premiummessage[1].creationDate.seconds * 1000 + offset * 60 * 60 * 1000).toISOString().slice(2, 4)
        let month = new Date(premiummessage[1].creationDate.seconds * 1000 + offset * 60 * 60 * 1000).toISOString().slice(5, 7)
        let day = new Date(premiummessage[1].creationDate.seconds * 1000 + offset * 60 * 60 * 1000).toISOString().slice(8, 10)
        let endvalue = month + "." + day + "." + year
        setdate(endvalue)
    }, []);

    const formatNumber = n => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };

    if (premiummessage[1]?.grouptitle !== undefined) {
        return (
            <button style={{ width: "80%", paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, borderStyle: "solid", borderColor: "#009ffd", borderWidth: 1, marginTop: 10, marginBottom: 10, borderRadius: 15 }}>

                <div onClick={() => navigate("/group?id=" + premiummessage[0])} style={{ display: "flex" }}>

                    <img loading="lazy" style={{ borderRadius: 15, height: 200 }} src={premiummessage[1]?.media} alt="Premsi Premium Message from Premsi user" />

                    <div style={{ marginLeft: 10, flex: 1, paddingTop: 20, display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <p style={{ width: "80%", marginBottom: 10, }}>{premiummessage[1].grouptitle}</p>
                        <p style={{ width: "80%" }}>{premiummessage[1].description}</p>
                        <p style={{ width: "80%", marginTop: 15 }}>Price: {premiummessage[1].price}$</p>
                        <p style={{ width: "80%" }}>Created: {date}</p>
                        <p style={{ width: "80%" }}>Sensitive: {premiummessage[1].sensitive === true ? "Yes" : "No"}</p>
                        <p style={{ width: "80%" }}>Still selling: {premiummessage[1].active === true ? "Yes" : "No"}</p>
                        <p style={{ width: "80%" }}>Monthly: {premiummessage[1].onetimefee === true ? "No" : "Yes"}</p>
                        <p style={{ width: "80%" }}>Bought: {premiummessage[1].bought} times</p>
                        <p style={{ width: "80%" }}>Members: {premiummessage[1].membersCount}</p>
                        <p style={{ width: "80%" }}>Messages: {premiummessage[1].messagesCount}</p>
                        <p style={{ width: "80%", marginTop: 15, fontWeight: "bold" }}>Earned: {Number(premiummessage[1].bought) * Number(premiummessage[1].price)}$</p>
                    </div>

                </div>

                <p style={{ width: "100%", marginBottom: 10, marginTop: 20 }}>See the whole chat in the App</p>
                <div onClick={() => navigate("/app")} style={{ marginTop: 10, cursor: "pointer", height: 30, width: "100%", backgroundColor: "#65c2f8", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ color: "white", fontSize: 15, textAlign: "center" }}>Payout earnings in our App</p>
                </div>
                <div onClick={() => (navigator.clipboard.writeText("www.premsi.com/group?id=" + premiummessage[0]), setcopied(true))} style={{ marginTop: 10, cursor: "pointer", height: 30, width: "100%", backgroundColor: "#65c2f8", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {copied ? <p style={{ color: "white", fontSize: 15, textAlign: "center" }}>Copied 🔗</p> : <p style={{ color: "white", fontSize: 15, textAlign: "center" }}>Share this group 🔗</p>}
                </div>
            </button>
        )
    }

    return (
        <button style={{ width: "80%", paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, borderStyle: "solid", borderColor: "#009ffd", borderWidth: 1, marginTop: 10, marginBottom: 10, borderRadius: 15 }}>

            <div className='premiummgrowinner' onClick={() => navigate("/pm?id=" + premiummessage[0])} style={{ display: "flex" }}>

                {premiummessage[1]?.type === "image"
                    ?
                    <img loading="lazy" style={{ borderRadius: 15, height: 200 }} src={premiummessage[1]?.media[0][1]} alt="Premsi Premium Message from Premsi user" />
                    :
                    (premiummessage[1]?.type === "video" ?
                        <img loading="lazy" style={{ borderRadius: 15, height: 200 }} src={premiummessage[1]?.media[0][1]} alt="Premsi Premium Message from Premsi user" />
                        :
                        <div className="Example__container__document">
                            <Document options={options} style={{ width: 200, height: 300 }} onLoadSuccess={onDocumentLoadSuccess} file={premiummessage[1]?.media}>
                                <Page
                                    pageNumber={1}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                    style={{ marginBottom: 30 }}
                                />
                            </Document>
                        </div>
                    )
                }

                <div style={{ marginLeft: 10, flex: 1, paddingTop: 20, display: "flex", alignItems: "center", flexDirection: "column" }}>
                    {premiummessage[1]?.type === "pdf" ? <p style={{ width: "80%", marginBottom: 10, }}>{premiummessage[1].title}</p> : null}
                    <p style={{ width: "80%" }}>{premiummessage[1].description}</p>
                    <p style={{ width: "80%", marginTop: 15 }}>Price: {premiummessage[1].price}$</p>
                    <p style={{ width: "80%", marginTop: 15 }}>Created: {date}</p>
                    <p style={{ width: "80%", marginTop: 15 }}>Amount Files: {premiummessage[1].amountfiles} 🏞️</p>
                    <p style={{ width: "80%" }}>Sensitive: {premiummessage[1].sensitive === true ? "Yes" : "No"}</p>
                    <p style={{ width: "80%" }}>Still selling: {premiummessage[1].active === true ? "Yes" : "No"}</p>
                    <p style={{ width: "80%" }}>Bought: {premiummessage[1].bought} times</p>
                    <p style={{ width: "80%", marginTop: 15, fontWeight: "bold" }}>Earned: {Number(premiummessage[1].bought) * Number(premiummessage[1].price)}$</p>
                </div>

            </div>

            {premiummessage[1]?.type === "pdf" ? <p style={{ width: "100%", marginBottom: 10, }}>See the whole pdf in the App</p> : null}
            <div onClick={() => navigate("/app")} style={{ marginTop: 10, cursor: "pointer", height: 30, width: "100%", backgroundColor: "#65c2f8", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p style={{ color: "white", fontSize: 15, textAlign: "center" }}>Payout earnings in our App</p>
            </div>
            <div onClick={() => (navigator.clipboard.writeText("www.premsi.com/pm?id=" + premiummessage[0]), setcopied(true))} style={{ marginTop: 10, cursor: "pointer", height: 30, width: "100%", backgroundColor: "#65c2f8", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {copied ? <p style={{ color: "white", fontSize: 15, textAlign: "center" }}>Copied 🔗</p> : <p style={{ color: "white", fontSize: 15, textAlign: "center" }}>Share it 🔗</p>}
            </div>
        </button>
    )
}
//                 uploaded with creationDate,  bought, 

function CreatePremiumMessageScreen() {
    let currentUser = useSelector(getCurrentUser).currentUser?.currentUser// ALSO WENNS n user gibt dann wird anderes screen angezeigt
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [pmtype, setpmtype] = useState(0);
    const [createpm, setcreatepm] = useState(false);
    const [file, setfile] = useState();
    const [userPremiumMessages, setuserPremiumMessages] = useState([]);

    // for errors
    const [pdftoobig, setpdftoobig] = useState(false);

    // for pdf
    const [numPages, setNumPages] = useState();
    const [copied, setcopied] = useState(false);
    const [pdftitle, setpdftitle] = useState("");

    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();

    // references for inputs
    const hiddenFileInputvideo = useRef(null);
    const hiddenFileInputimage = useRef(null);
    const hiddenFileInputpdf = useRef(null);
    const hiddenFileInputgroup = useRef(null);

    const [sensitive, setsensitive] = useState(false);
    const [caption, setcaption] = useState("");
    const [price, setprice] = useState("");

    // for groups only
    const [grouptitle, setgrouptitle] = useState("");
    const [monthlypayment, setmonthlypayment] = useState(true)

    // for video only
    const [vidtoolong, setvidtoolong] = useState(false)
    const [sthempty, setsthempty] = useState(false);

    // for uploading process of premium messages
    const [requestRunning, setRequestRunning] = useState(false);
    const [uploadingerror, setuploadingerror] = useState(false);
    const [guid, setguid] = useState([])
    const [uploadbyt, setuploadbyt] = useState([0, 0])
    const [uploadingdonesuccessfully, setuploadingdonesuccessfully] = useState(false);
    const [videofileforupload, setvideofileforupload] = useState();
    const [notloggedin, setnotloggedin] = useState(false);

    const [changedlogin, setchangedlogin] = useState(false);


    // for sharing new PM
    const [endpmid, setpmid] = useState("");

    // array to save all the image and or videos
    const [imagearray, setimagearray] = useState([]);
    const [videoarrayforupload, setvideoarrayforupload] = useState([]);
    const [amountfiles, setamountfiles] = useState(0);


    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClickimage = event => {
        setfile()
        // 3pdf,1video,24img
        if (pmtype === 1) {
            //vid
            hiddenFileInputvideo.current.click();
        } if (pmtype === 2) {
            //pic
            hiddenFileInputimage.current.click();
        } if (pmtype === 3) {
            //pdf
            hiddenFileInputpdf.current.click();
        } if (pmtype === 4) {
            //group
            hiddenFileInputgroup.current.click();
        }
    };

    const onResize = useCallback((entries) => {
        const [entry] = entries;
        //console.log("resized", entry.contentRect.width)

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    async function handleImageUpload(event) {

        //here check

        const imageFile = event.target.files[0];
        //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            // play around with options
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            //console.log("compressed file", compressedFile)
            //setfile(URL.createObjectURL(compressedFile)) 
            setamountfiles(amountfiles + 1)
            setimagearray([...imagearray, URL.createObjectURL(compressedFile)])
            console.log("seted handleImageUpload")
        } catch (error) {
            //console.log(error);
        }
    }

    async function handleImageUploadForGroup(event) {

        //here check

        const imageFile = event.target.files[0];
        //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            // play around with options
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            //console.log("compressed file", compressedFile)
            setfile(URL.createObjectURL(compressedFile))

        } catch (error) {
            //console.log(error);
        }

    }

    async function handleVideoUpload(e) {

        //setvideofileforupload(e.target.files[0])
        //setfile(URL.createObjectURL(e.target.files[0]))

        //let videoresults = first: for upload, second: thumbnail
        let videoresults = [e.target.files[0], URL.createObjectURL(e.target.files[0])]
        setvideoarrayforupload([...videoarrayforupload, videoresults])
        setamountfiles(amountfiles + 1)
        console.log("seted handleVideoUpload")
    }

    useEffect(() => {
        // here check
        //console.log("this is created file ", file, sensitive)
        // do checks
        setuploadingdonesuccessfully(false)
        if (pmtype === 1) {
            //vid
            //console.log("in video")
            // get duration of video by creating a theoretical video component
            var video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                // here now can check if video is too long
                setvidtoolong(video.duration > (60 * 5) ? true : false)
                // setvidtoolong(video.duration)
            }
            video.src = file
        } if (pmtype === 2) {
            //pic
            //console.log("this is compressedimage", file)
        } if (pmtype === 3) {
            //pdf
            if (file?.size > 2048 * 1000) {
                setpdftoobig(true)
            }
        } if (pmtype === 4) {
            //group
            //console.log("this is compressedimage", file)

        }
    }, [file, sensitive]);

    useEffect(() => {
        setfile()
        setprice("")
        setcaption("")
        setgrouptitle("")
        setpdftitle("")
        setmonthlypayment(true)
        setsthempty(false)
        setuploadingdonesuccessfully(false)
        setimagearray([])
        setvideoarrayforupload([])
        setamountfiles(0)
    }, [pmtype]);

    const loadcurrentofferings = () => {
        if (currentUser === undefined || currentUser === null || pmtype === 0) {
            //console.log("CreatePremiumMessageScreen currentuser", currentUser)
            return;
        }
        if (pmtype === 1) {
            //console.log("get images")
            setuserPremiumMessages([])
            getPremiumMessagesOfUser(auth.currentUser.uid, "video").then(setuserPremiumMessages)
        } else if (pmtype === 2) {
            //console.log("get videos")
            setuserPremiumMessages([])
            getPremiumMessagesOfUser(auth.currentUser.uid, "image").then(setuserPremiumMessages)
        } else if (pmtype === 3) {
            //console.log("get pdf")
            setuserPremiumMessages([])
            getPremiumMessagesOfUser(auth.currentUser.uid, "pdf").then(setuserPremiumMessages)
        } else if (pmtype === 4) {
            //console.log("get groups")
            setuserPremiumMessages([])
            getGroupsByUserId(auth.currentUser.uid, currentUser).then(setuserPremiumMessages)
        }
    }

    const createPremiumMessage = () => {
        // search function in app
        setuploadingdonesuccessfully(false)
        if (currentUser === null && !changedlogin || currentUser === undefined && !changedlogin) {
            setnotloggedin(true)
            return
        }
        console.log("check in app what all needed to create specific premium message", vidtoolong, (pmtype === 1 && amountfiles === 0), (pmtype === 2 && amountfiles === 0), (pmtype === 4 && grouptitle === ""), (pmtype === 3 && pdftitle === ""), (pmtype === 4 && file === undefined), (pmtype === 3 && file === undefined), caption === "", price === "", (price < 5 && price > 0.01), price < 0)
        if (vidtoolong || (pmtype === 1 && amountfiles === 0) || (pmtype === 2 && amountfiles === 0) || (pmtype === 4 && grouptitle === "") || (pmtype === 3 && pdftitle === "") || (pmtype === 4 && file === undefined) || (pmtype === 3 && file === undefined) || caption === "" || price === "" || (price < 5 && price > 0.01) || price < 0) {
            setsthempty(true)
            return
        } else {
            setsthempty(false)
        }
        // see in app to what pay attention
        if (pmtype === 1) {
            //vid
            uploadvideo()
        } if (pmtype === 2) {
            //pic
            uploadpicture()
        } if (pmtype === 3) {
            //pdf
            if (file?.size > 2048 * 1000) {
                setpdftoobig(true)
                return
            }
            uploadpdf()
        } if (pmtype === 4) {
            //group
            initcreateGroup()
        }
    }

    // following function : uploadpicture, uploadpdf, useEffect with guid, gethlslinkandpost,uploadvideo are for uploading

    const uploadingDone = (pmid) => {
        //console.log("uploadingdone", pmid)
        setpmid(pmid)
        setuploadingdonesuccessfully(true)
    }

    const uploadpicture = async () => {
        setRequestRunning(true)
        let imagelinks = {}

        Promise.all(imagearray.map(async (mappedimage, i) => {
            let randomid = uuid()
            await saveMediaToStorage(mappedimage, `premiummessageimage/${auth.currentUser.uid}/${randomid}`).then((res) => {
                let imagelink = res
                imagelinks[i] = ["novideo", imagelink]
            })
        })).then(() => {
            dispatch(createPremiumMessageFB(caption, imagelinks, sensitive, "image", price, currentUser, "nopdf", amountfiles))
                .then((premiummessageid) => (/*console.log("success", res)*/ setRequestRunning(false), uploadingDone(premiummessageid))) // change user to send to profile
                .catch((e) => {
                    //console.log(e)
                    setRequestRunning(false)
                    alert("There was an error uploading your media. Please try again.")
                    setuploadingerror(true)

                })
        })
    };

    const uploadpdf = async () => {
        setRequestRunning(true)
        //console.log("uploadpic")
        let randomid = uuid()
        let urlpdf = URL.createObjectURL(file)
        saveMediaToStorage(urlpdf, `premiummessagepdf/${auth.currentUser.uid}/${randomid}`).then((res) => {
            let pdflink = res
            //console.log("this is res", res) (caption, imagelinks, sensitive, "image", price, currentUser, "nopdf", amountfiles)
            dispatch(createPremiumMessageFB(caption, pdflink, sensitive, "pdf", price, currentUser, pdftitle, amountfiles))
                .then((premiummessageid) => (/*console.log("success", res)*/ setRequestRunning(false), uploadingDone(premiummessageid))) // change user to send to profile
                .catch((e) => {
                    //console.log(e)
                    setRequestRunning(false)
                    alert("There was an error uploading your media. Please try again.")
                    setuploadingerror(true)

                })
        })

    };


    useEffect(() => {
        //console.log(videofileforupload)
        async function us() {

            guid.map(async (singleguid, i) => {

                if (singleguid !== "") {

                    //console.log("this is guid sdfn" + guid)
                    let now = Date.now() + 100000

                    await auth.currentUser.getIdToken(true).then((tok) => {
                        const getBunnyHash = httpsCallable(functions, 'getBunnyHash');
                        getBunnyHash({ now: now, guid: singleguid, idtoken: tok, user: auth.currentUser.uid })
                            .then((result) => {
                                // Read result of the Cloud Function
                                console.log("resulttt getBunnyHash", result)
                                if (result.data !== "error") {
                                    let hash = result.data

                                    // Create a new tus upload
                                    var upload = new tus.Upload(videoarrayforupload[i][0], {
                                        endpoint: "https://video.bunnycdn.com/tusupload",
                                        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
                                        headers: {
                                            AuthorizationSignature: hash, // SHA256 signature (library_id + api_key + expiration_time + video_id)
                                            AuthorizationExpire: now, // Expiration time as in the signature,
                                            VideoId: singleguid, // The guid of a previously created video object through the Create Video API call
                                            LibraryId: 71880,
                                        },
                                        metadata: {
                                            title: `${auth.currentUser.uid}`,
                                        },
                                        onError: function (error) { /*console.log(error)*/ },
                                        onProgress: function (bytesUploaded, bytesTotal) { setuploadbyt([bytesUploaded, bytesTotal]) },
                                        onSuccess: function () {
                                            //gethlslinkandpost()
                                        }
                                    })

                                    // Check if there are any previous uploads to continue.
                                    upload.findPreviousUploads().then(function (previousUploads) {
                                        // Found previous uploads so we select the first one. 
                                        if (previousUploads.length) {
                                            upload.resumeFromPreviousUpload(previousUploads[0])
                                        }

                                        // Start the upload
                                        upload.start()
                                    })

                                } else {
                                    console.log("error getBunnyHash")
                                }
                            })
                            .catch((error) => {
                                // Getting the Error details.
                                const code = error.code;
                                const message = error.message;
                                const details = error.details;
                                console.log("errorcloudfunction getBunnyHash", error)
                                console.log("error getBunnyHash")
                            });
                    }).catch((e) => {
                        console.log("error getBunnyHash", e)
                    })

                } else {
                    console.log("guid empty")
                }

            })

        }
        us().then(() => {
            if (guid.length === 0) {
                return
            } else {
                gethlslinkandpost()
            }
        })

    }, [guid]);

    const gethlslinkandpost = async () => {
        let finishedvideoarray = {}

        async function us() {
            guid.map(async (singleguid, i) => {
                const videolink = 'https://vz-83018592-76b.b-cdn.net/' + singleguid + '/playlist.m3u8'
                const thumblink = 'https://vz-83018592-76b.b-cdn.net/' + singleguid + '/thumbnail.jpg'

                finishedvideoarray[i] = [videolink, thumblink]
            })
        }

        us().then(() => {
            dispatch(createPremiumMessageFB(caption, finishedvideoarray, sensitive, "video", price, currentUser, "nopdf", amountfiles))
                .then((premiummessageid) => (setRequestRunning(false), uploadingDone(premiummessageid))) // change user to send to profile
                .catch((e) => {
                    //console.log(e)
                    setRequestRunning(false)
                    alert("There was an error uploading your media. Please try again.")
                    setuploadingerror(true)
                })
        })
    }

    const uploadvideo = () => {
        setRequestRunning(true)
        //f78
        auth.currentUser.getIdToken(true).then((tok) => {
            const getBunnyGuid = httpsCallable(functions, 'getBunnyGuid');
            getBunnyGuid({ idtoken: tok, user: auth.currentUser.uid, amountfiles: amountfiles })
                .then((result) => {
                    // Read result of the Cloud Function
                    //console.log("resulttt getBunnyGuid", result)
                    if (result.data !== "error") {
                        setguid(result.data);
                    } else {
                        //console.log("error uuuuploadvideo")
                    }
                })
                .catch((error) => {
                    // Getting the Error details.
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    //console.log("errorcloudfunction", error)
                    //console.log("error fffuploadvideo")
                });
        }).catch((e) => {
            //console.log("error uploadvideo", e)
        })
    }

    const initcreateGroup = async () => {
        setRequestRunning(true)
        //console.log("uploadpic")
        let randomid = uuid()
        saveMediaToStorage(file, `groups/${auth.currentUser.uid}/${randomid}`).then((res) => {
            let imagelink = res
            //console.log("savemediatostorage", res)
            createGroup(caption, imagelink, sensitive, price, currentUser, grouptitle, monthlypayment)
                .then((groupid) => (/*console.log("success", groupid)*/ setRequestRunning(false), uploadingDone(groupid))) // change user to send to profile
                .catch((e) => {
                    //console.log(e)
                    setRequestRunning(false)
                    alert("There was an error uploading your media. Please try again.")
                    setuploadingerror(true)
                })
        })

    }

    return (
        <>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi | Create a Premium Message and sell with Premsi" />
                <meta property="og:description" content="Premsi | Create a Premium Message and sell with Premsi . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/calculator" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
                <meta property="og:site_name" content="Premsi - sell digital content" />

                <title>Premsi | Create a Premium Message and sell</title>
                <meta name="description" content="Premsi | Create a Premium Message and sellwith Premsi . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <link rel="canonical" href="https://premsi.com/calculator" />
            </Helmet>
            <Nav />
            {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}

            <div className='whatispremsimainmaincreatepm' style={{ minHeight: 500 }}>
                <div className='firstheader'>
                    <p style={{ fontSize: 23, fontWeight: "bold", color: "black" }}>Create a Premium Message and sell with Premsi </p>
                    <img loading="lazy" src={require('../../images/pblue-min.webp')} style={{ height: 25, width: 25, borderRadius: 15 }} alt="Premsi.com logo app icon" />
                </div>

                <p className='premsiexplanationp'>Premsi is the social media where you can post, market and sell all your digital content in our App + Website in 30sec 🔥</p>

                <div className='selectpmtype'>
                    <div className='selectpmtypebtn' onClick={() => (setpmtype(1), setuserPremiumMessages([]), setfile(), setcreatepm(false))}>
                        <p style={{ color: "white" }}>Video</p>
                    </div>
                    <div className='selectpmtypebtn' onClick={() => (setpmtype(2), setuserPremiumMessages([]), setfile(), setcreatepm(false))} >
                        <p style={{ color: "white" }}>Picture</p>
                    </div>
                    <div className='selectpmtypebtn' onClick={() => (setpmtype(3), setuserPremiumMessages([]), setfile(), setcreatepm(false))} >
                        <p style={{ color: "white" }}>PDF</p>
                    </div>
                    <div className='selectpmtypebtn' onClick={() => (setpmtype(4), setuserPremiumMessages([]), setfile(), setcreatepm(false))} >
                        <p style={{ color: "white" }}>Group</p>
                    </div>
                </div>

                {pmtype === 4 ? <p style={{ fontSize: 16, marginBottom: 5, textAlign: "center", width: "60%", marginTop: 20 }}>Create a Group where your members will be charged either monthly or set up a one time fee! Your members will be only able to see your posted content. In our App you can add writers to the group that can also post in it.</p> : null}


                {pmtype === 0 || createpm === true ? null :
                    <>
                        <div onClick={() => setcreatepm(true)} style={{ marginTop: 20, cursor: "pointer", width: 220, height: 60, borderStyle: "solid", borderColor: "black", borderWidth: 1, borderRadius: 15, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: 14, marginBottom: 5, textAlign: "center" }}>Create {pmtype === 1 ? "Video PM" : (pmtype === 2 ? "Picture PM" : (pmtype === 3 ? "PDF PM" : "Group"))}</p>
                            <CiCirclePlus color='black' size={20} />
                        </div>
                        {/*userPremiumMessages.length === 10 ? <div style={{ marginTop: 20, cursor: "pointer", width: 220, height: 60, borderStyle: "solid", borderColor: "black", borderWidth: 1, borderRadius: 15, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: 14, marginBottom: 5, textAlign: "center" }}>Max of 10 {pmtype === 1 ? "Video PMs" : (pmtype === 2 ? "Picture PMs" : (pmtype === 3 ? "PDF PMs" : "Groups"))} achieved</p>
                            <BsExclamationDiamond color='red' size={20} />
                        </div>*/}
                        {currentUser === null || currentUser === undefined
                            ?
                            null :
                            <div onClick={() => (loadcurrentofferings(), setcreatepm(false))} style={{ cursor: "pointer", marginTop: 20, height: 40, width: 250, backgroundColor: "#65c2f8", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <p style={{ color: "white", fontSize: 14 }}>Load currently offered {pmtype === 1 ? "Video PMs" : (pmtype === 2 ? "Picture PMs" : (pmtype === 3 ? "PDF PMs" : "Groups"))}</p>
                            </div>
                        }
                    </>
                }

                {createpm
                    ?
                    <div style={{ width: "80%", backgroundColor: "white", marginTop: 30, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <p style={{ fontSize: 20, marginBottom: 5, textAlign: "center", fontWeight: "bold" }}>Sell {pmtype === 1 ? "Video" : (pmtype === 2 ? "Picture" : (pmtype === 3 ? "PDF" : "monthly paid Group"))}:</p>

                        {amountfiles === 4
                            ?
                            <div onClick={() => console.log("delete video")} style={{ width: 200, backgroundColor: "white", height: 100, borderRadius: 25, borderStyle: "solid", borderColor: "#009ffd", borderWidth: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <p style={{ color: "#009ffd", marginBottom: 10, fontSize: 15 }}>You already chose 4 files.</p>
                                <p style={{ color: "#009ffd", marginBottom: 10, fontSize: 15 }}>Delete one to add another</p>
                            </div>
                            :
                            <button onClick={handleClickimage} style={{ width: 200, backgroundColor: "white", height: 100, borderRadius: 25, borderStyle: "solid", borderColor: "#009ffd", borderWidth: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <p style={{ fontWeight: "bold", color: "#009ffd", marginBottom: 10 }}>Upload {pmtype === 1 ? "Video" : (pmtype === 2 ? "Image" : (pmtype === 3 ? "PDF" : "Group Image"))}</p>
                                <CiCirclePlus color='#009ffd' size={20} />
                            </button>}

                        <input type="file" style={{ display: "none" }} accept="video/*" ref={hiddenFileInputvideo} onChange={(e) => (handleVideoUpload(e))} />
                        <input type="file" style={{ display: "none" }} accept=".pdf" ref={hiddenFileInputpdf} onChange={(event) => setfile(event.target.files[0])} />
                        <input type="file" style={{ display: "none" }} accept="image/*" ref={hiddenFileInputimage} onChange={(e) => (/*console.log("selected image")*/ handleImageUpload(e))} />
                        <input type="file" style={{ display: "none" }} accept="image/*" ref={hiddenFileInputgroup} onChange={(e) => (/*console.log("selected image")*/ handleImageUploadForGroup(e))} />

                        {/*height: (file ? (window.innerWidth > 1024 ? 400 : 550) : 200) */}
                        <div className='uploadfileouterdiv' style={{}}>

                            {file && pmtype === 3 ?
                                <div style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", borderWidth: 1, borderColor: "gray", borderStyle: "solid", borderRadius: 15 }}>
                                    <Document options={options} style={{ width: 200, height: 300 }} onLoadSuccess={onDocumentLoadSuccess} file={file}>
                                        <Page
                                            pageNumber={1}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                            width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                            style={{ marginBottom: 30 }}
                                        />
                                    </Document>
                                    {file ? (pdftoobig ? <p style={{ color: "red" }}>Max 2MB PDF</p> : <p style={{ color: "green" }}>Your file is good to go</p>) : null}
                                    {file ? <p style={{ fontSize: 12, marginTop: 5 }}>Only 1st Page is shown in Preview</p> : null}

                                </div>
                                : null}

                            {file && pmtype === 4 ?
                                <div style={{ height: 200, aspectRatio: 12 / 16, borderRadius: 20, backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img loading="lazy" style={{ width: "100%", objectFit: "contain", aspectRatio: 12 / 16, borderRadius: 20, borderWidth: 0.5, borderColor: "black", borderStyle: "solid" }} src={file} alt="Premsi Premium Message from Premsi user" />
                                </div>
                                : null}

                            {/* for images: imagearray here check see all images and videos */}
                            {amountfiles !== 0 && pmtype === 2 ?
                                (imagearray.map((mappedimage, i) =>
                                    <div style={{ height: 200, aspectRatio: 12 / 16, borderRadius: 20, backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img loading="lazy" style={{ width: "100%", objectFit: "contain", aspectRatio: 12 / 16, borderRadius: 20, borderWidth: 0.5, borderColor: "black", borderStyle: "solid" }} src={mappedimage} alt="Premsi Premium Message from Premsi user" />
                                    </div>
                                )
                                )
                                : null}

                            {/* for videos: videoarrayforupload */}
                            {amountfiles !== 0 && pmtype === 1 ?
                                (videoarrayforupload.map((mappedvideo, i) =>
                                    <div style={{ height: 200, aspectRatio: 12 / 16, borderRadius: 20, backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <video style={{ height: "100%", aspectRatio: 12 / 16, borderRadius: 20 }} controls>
                                            <source src={mappedvideo[1]} />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                ))
                                : null}

                        </div>

                        <div className='firstinputrow'>
                            {/*    // for group: image, grouptitle, groupdescription, sensitive, onetimefee, monthly price or onetimeprice */}
                            {pmtype === 4
                                ?
                                <>
                                    <div className='pminputdiv'>
                                        <p className='pminputp'>Group Title:</p>
                                        <input onChange={(e) => setgrouptitle(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='text' maxLength={250} placeholder='Title' />
                                    </div>

                                    <div className='pminputdiv'>
                                        <p className='pminputp'>Group Description:</p>
                                        <input onChange={(e) => setcaption(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='text' maxLength={400} placeholder='Description' />
                                    </div>

                                    <div className='pminputdiv'>
                                        <p className='pminputp' style={{ textAlign: "center" }}>Monthly Payment:</p>
                                        <button className='senstextbtn' type='button' style={{ border: "0", width: 70, borderRadius: 15, height: 30, backgroundColor: "rgba(255,255,255,0.3)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Switch size='big' color='success' checked={monthlypayment} onChange={() => setmonthlypayment(!monthlypayment)} />
                                        </button>
                                    </div>

                                </>
                                :
                                <>
                                    <div className='pminputdiv'>
                                        <p className='pminputp'>Caption:</p>
                                        <input onChange={(e) => setcaption(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='text' maxLength={250} placeholder='Caption of Premium Message' />
                                    </div>

                                    <div className='pminputdiv'>
                                        <p className='pminputp'>Price in USD:</p>
                                        <input onInput={(event) => event.target.value = event.target.value.slice(0, 4)} onChange={(e) => setprice(e.target.value.slice(0, 4))} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='number' maxLength={5} placeholder='Price of Premium Message' />
                                    </div>

                                    <div className='pminputdiv'>
                                        <p className='pminputp' style={{ textAlign: "center" }}>Sensitive:</p>
                                        <button className='senstextbtn' type='button' style={{ border: "0", width: 70, borderRadius: 15, height: 30, backgroundColor: "rgba(255,255,255,0.3)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Switch size='big' color='success' checked={sensitive} onChange={() => setsensitive(!sensitive)} />
                                        </button>
                                    </div>
                                </>
                            }

                        </div>

                        {pmtype === 3
                            ?
                            <div style={{ display: "flex", width: "100%", backgroundColor: "white", alignItems: "center", justifyContent: "space-around", flexDirection: "row" }}>
                                <div className='pminputdiv'>
                                    <p className='pminputp'>PDF title:</p>
                                    <input onChange={(e) => setpdftitle(e.target.value)} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='text' maxLength={250} placeholder='Title' />
                                </div>

                            </div>
                            : null}

                        {pmtype === 4
                            ?
                            <div className='firstinputrow'>
                                <div className='pminputdiv'>
                                    <p className='pminputp'>{monthlypayment ? "Monthly Price" : "One-Time fee"} in USD:</p>
                                    <input onInput={(event) => event.target.value = event.target.value.slice(0, 4)} onChange={(e) => setprice(e.target.value.slice(0, 4))} className='searchinput_searchscreen' style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#009ffd", width: 200 }} type='number' maxLength={5} placeholder='Price of Premium Message' />
                                </div>

                                <div className='pminputdiv'>
                                    <p className='pminputp' style={{ textAlign: "center" }}>Sensitive:</p>
                                    <button className='senstextbtn' type='button' style={{ border: "0", width: 70, borderRadius: 15, height: 30, backgroundColor: "rgba(255,255,255,0.3)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Switch size='big' color='success' checked={sensitive} onChange={() => setsensitive(!sensitive)} />
                                    </button>
                                </div>

                                <div className='pminputdiv'>
                                    {/*just that equally layout distributed */}
                                </div>
                            </div>
                            : null}

                        {requestRunning
                            ?
                            <div style={{ marginTop: 40, marginBottom: 20, height: 40, width: 300, backgroundColor: "#009ffd", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Oval
                                    height={30}
                                    width={30}
                                    color="#009FFD"
                                    wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="white"
                                    strokeWidth={5}
                                    strokeWidthSecondary={5}
                                />
                            </div>
                            :
                            (uploadingdonesuccessfully
                                ?
                                <>
                                    <div onClick={() => (pmtype === 4 ? navigator.clipboard.writeText("www.premsi.com/group?id=" + endpmid) : navigator.clipboard.writeText("www.premsi.com/pm?id=" + endpmid))} style={{ marginTop: 40, marginBottom: 0, cursor: "pointer", height: 40, width: 300, backgroundColor: "green", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <p style={{ color: "white" }}>Done, share it to the world  🔗</p>
                                    </div>
                                    <div onClick={() => navigate("/app")} style={{ marginTop: 10, marginBottom: 0, cursor: "pointer", height: 40, paddingLeft: 20, paddingRight: 20, paddingTop: 0, paddingBottom: 0, backgroundColor: "#85cbf4", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <p style={{ color: "white", fontSize: 13, textDecoration: "underline" }}>Market your {pmtype === 4 ? "group" : "Premium Message"} with free posts in our App!</p>
                                    </div>
                                    <div onClick={() => (pmtype === 4 ? navigator.clipboard.writeText("www.premsi.com/group?id=" + endpmid) : navigator.clipboard.writeText("www.premsi.com/pm?id=" + endpmid))} style={{ marginTop: 10, marginBottom: 20, cursor: "pointer", height: 40, paddingLeft: 20, paddingRight: 20, paddingTop: 0, paddingBottom: 0, backgroundColor: "#85cbf4", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <p style={{ color: "white", fontSize: 13 }}>{pmtype === 4 ? "www.premsi.com/group?id=" + endpmid + "   🔗" : "www.premsi.com/pm?id=" + endpmid + "   🔗"}</p>
                                    </div>
                                </>
                                :
                                (notloggedin
                                    ?
                                    (changedlogin
                                        ?
                                        <div onClick={() => (createPremiumMessage())} style={{ marginTop: 40, marginBottom: 20, cursor: "pointer", height: 40, width: 300, backgroundColor: "#009ffd", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <p style={{ color: "white" }}>Create Premium {pmtype === 1 ? "Video" : (pmtype === 2 ? "Image" : (pmtype === 3 ? "PDF" : "Group"))} 🔥</p>
                                        </div>
                                        :
                                        <div style={{ marginTop: 40, paddingTop: 20, width: "100%", borderTopWidth: 0.6, borderBottomWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: "solid", borderColor: "#009ffd", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                            <h1 style={{ textAlign: "center", fontSize: 20 }}>Sign In to create PMs ❤️</h1>
                                            <SignUpScreenNotHome setchangedlogin={setchangedlogin} />
                                        </div>
                                    )
                                    :
                                    <div onClick={() => (createPremiumMessage())} style={{ marginTop: 40, marginBottom: 20, cursor: "pointer", height: 40, width: 300, backgroundColor: "#009ffd", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <p style={{ color: "white" }}>Create Premium {pmtype === 1 ? "Video" : (pmtype === 2 ? "Image" : (pmtype === 3 ? "PDF" : "Group"))} 🔥</p>
                                    </div>)
                            )

                        }

                        {vidtoolong ? <p style={{ color: "red", fontSize: 14, marginBottom: 15 }} > One of your videos is too long. Videos can be at max 5min long.</p> : null}
                        {sthempty ? <p style={{ color: "red", fontSize: 13, marginBottom: 8 }} > Something is empty or there is an error in a field.</p> : null}
                        {sthempty ? <p style={{ color: "red", fontSize: 13, marginBottom: 15 }} > Please fill out every field and upload a file.</p> : null}
                        {sthempty ? <p style={{ color: "red", fontSize: 13, marginBottom: 8 }} > Please remember the price has to be either 0$(free) or equal/above 5$.</p> : null}

                        {uploadingerror ? <p style={{ color: "red", fontSize: 13, marginBottom: 15 }} > There has been an error creating your Premium Message. Please retry!</p> : null}

                    </div>
                    :
                    (pmtype === 0 || userPremiumMessages.length === 0 ? null :
                        <>
                            <p style={{ marginTop: 20, fontSize: 20, fontWeight: "bold" }}>{pmtype === 1 ? "Your Premium Videos" : (pmtype === 2 ? "Your Premium Images" : (pmtype === 3 ? "Your Premium PDFs" : "Your Groups"))}</p>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                {userPremiumMessages.map((premiummessage) => (
                                    <PremiumMessageRow premiummessage={premiummessage} />
                                )
                                )}
                            </div>
                            <p onClick={() => navigate("/app")} style={{ width: "80%", fontSize: 13, marginTop: 7, marginBottom: 10, textDecoration: "underline" }}>Only your last 5 Premium Messages are shown. To see them all and more insight please download our App.</p>

                            <p onClick={() => navigate("/app")} style={{ width: "80%", fontSize: 13, marginTop: 7, marginBottom: 10, textDecoration: "underline" }}>To delete Premium Messages please download our app</p>
                        </>
                    )}

            </div >
            <Footer />
        </>
    )
}

export default CreatePremiumMessageScreen

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './PostScreen.css';
import Nav from '../../components/Nav/Nav'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../features/currentUser'
import { Oval, MagnifyingGlass } from 'react-loader-spinner'
import { getPostWithId, getUserInfoWithId } from '../../services/f';
import { BsPlusCircle, BsInfoCircle, BsFillCheckCircleFill, BsFillHeartFill } from 'react-icons/bs'  //https://react-icons.github.io/react-icons
import { FaComment, FaShare } from 'react-icons/fa'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { PiUserCircleLight } from 'react-icons/pi'
import Footer from '../../components/Footer';
import { auth } from '../../firebase';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { Helmet } from 'react-helmet';
import { TwitterShareButton } from 'react-twitter-embed';
//see which one google indees and which notif there are dofferences
//check faq post app profile
function PostScreen() {
  // http://localhost:3000/post?id=sdffsdf
  // http://localhost:3000/post?id=MFbwMsG0SkVnOYTUMZRKeshqBmA2
  const [searchParams, setSearchParams] = useSearchParams();
  let currentUser = useSelector(getCurrentUser).currentUser?.currentUser// ALSO WENNS n user gibt dann wird anderes screen angezeigt
  const navigate = useNavigate()

  const [post, setpost] = useState(null);
  const [postuserinfo, setpostuserinfo] = useState(null);
  const [copiedlink, setcopiedlink] = useState(false);

  let [postimageurl, setpostimageurl] = useState("")

  const formatNumber = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  let titledescription = "Post description of " + postuserinfo?.displayName + "Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute."
  let titletext = "Premsi - Post of " + postuserinfo?.displayName

  useEffect(() => {
    //console.log("got post", post, post === null)
    if (post === "postnotexists" || post === "nopremium" || post === null) {
      return
    } else {
      setpostimageurl(post[1]?.type === "image" ? post[1]?.media[0] : post[1]?.media[1])
    }
  }, [post]);

  useEffect(() => {
    // check if user or psot available then query for them and show them
    // get specific   searchParams.get("code")

    let postid = searchParams.get("id")
    if (postid === null || postid === undefined || postid === "") {
      setpost("postnotexists")
    } else {
      getPostWithId(postid, currentUser).then((res) => {
        //console.log("getPostWithId result", res)
        if (res === "postnotexists") {
          setpost("postnotexists")
          return
        } else if (res === "nopremium") {
          setpost("nopremium")
          return
        } else {
          setpost(res)
          getUserInfoWithId(res[1].creator).then((userinfo) => {
            //console.log("these are getUserInfoWithId", userinfo)
            setpostuserinfo(userinfo)
          })
        }
      })
    }

  }, []);

  return (
    <div className='postscreen'>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Premsi | Post - Easily sell your digital content❤️" />
        <meta property="og:description" content="Premsi | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
        <meta property="og:url" content="https://premsi.com/post" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
        <meta property="og:site_name" content="Premsi - sell digital content" />
        <link rel="canonical" href="https://premsi.com/post" />

        <title>{titletext}</title>
        <meta name="description" content={String(titledescription)} />

        <meta name="twitter:title" content="Premsi" />
        <meta name="twitter:description" content={`New Premsi.com post from ${postuserinfo?.displayName}`} />
        <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Nav />
      {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}

      <div className='postscreen__body'>

        {post === null ?
          <div>
            <Oval
              height={40}
              width={40}
              color="#009FFD"
              wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="white"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
            <p style={{ marginBottom: 3, textAlign: "center" }}>Loading post...</p>
            <p style={{ textAlign: "center", fontSize: 12 }}>If this takes too long please refresh this window.</p>
          </div>
          :
          (
            post === "postnotexists"
              ?
              <div>
                <MagnifyingGlass
                  height={40}
                  width={40}
                  color="#009FFD"
                  wrapperStyle={{ marginTop: 20, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="white"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <h1 style={{ marginBottom: 3, textAlign: "center", color: "black", fontSize: 16 }}>No Post...</h1>
                <p style={{ textAlign: "center", fontSize: 12, marginTop: 20 }}>It seems that you have a wrong link and this post doesn't exist</p>
                <p style={{ textAlign: "center", fontSize: 12 }}>Please recheck your link.</p>
                <div style={{ display: "flex", alignItems: "center", marginTop: 20, justifyContent: "center", width: "100%", marginBottom: 5 }}>
                  <button onClick={() => navigate("/")} style={{ fontSize: 14, fontWeight: "bold", paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10, backgroundColor: "#009FFD", color: "white", borderWidth: 0 }}>Explore others</button>
                </div>
              </div>
              :
              (post === "nopremium"
                ?
                <div>
                  <MagnifyingGlass
                    height={40}
                    width={40}
                    color="#009FFD"
                    wrapperStyle={{ marginTop: 20, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="white"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                  <p style={{ marginBottom: 20, textAlign: "center" }}>  No Premium...</p>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ marginBottom: 20, textAlign: "center", width: "70%" }}>Unfortunately, you wanted to view a premium post but are not subscribed to Premsi Premium. </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ marginBottom: 3, textAlign: "center", color: "#009FFD", width: "70%" }}>Subscribe to Premsi Premium for 3.49$ per month to view all premium posts from all creators.</p>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginTop: 20, marginBottom: 5 }}>
                    <button onClick={() => navigate("/profile")} style={{ fontSize: 14, paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 10, backgroundColor: "white", borderWidth: 0 }}>More info...</button>
                  </div>
                </div>
                :
                <>
                  <div className='postscreen__info'>
                    <div className='profilepicdiv'>

                      {postuserinfo?.photoUrl === "" || postuserinfo === null || postuserinfo === undefined
                        ?
                        <PiUserCircleLight className='avatarpostscreen_nopic' size={38} color='white' alt='no profile picture' />
                        :
                        <img loading="lazy" style={{ pointerEvents: "none" }} className='avatarpostscreen' src={postuserinfo?.photoUrl} alt="user user pic" />
                      }

                    </div>

                    <div className='postscreen__details'>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button onClick={() => navigate("/user?id=" + post[1]?.creator)} className='useremail'>{postuserinfo?.displayName}</button>
                        {postuserinfo?.verified //localhost:3000/user?id=MFbwMsG0SkVnOYTUMZRKeshqBmA2
                          ?
                          <div style={{ marginLeft: 10, height: 22, width: 22, borderRadius: 30, backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <BsFillCheckCircleFill size={20} color='blue' alt='verified account' />
                          </div>
                          :
                          null
                        }
                      </div>

                      <div className='userprofilecounts'>

                        <div style={{ textAlign: "center" }}>
                          <p style={{ fontSize: 12, fontWeight: "bold" }}>Follower</p>
                          <p style={{ fontSize: 12 }}>{formatNumber(postuserinfo?.followers)}</p>
                        </div>

                        <div style={{ textAlign: "center" }}>
                          <p style={{ fontSize: 12, fontWeight: "bold" }}>Premium posts</p>
                          <p style={{ fontSize: 12 }}>{formatNumber(postuserinfo?.amountpremiumposts)}</p>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className='postdiv'>
                    {post[1]?.type === "image" ?
                      <img
                        loading="lazy"
                        key={post[0]}
                        src={post[1]?.type === "image" ? post[1]?.media[0] : post[1]?.media[1]}
                        style={{ pointerEvents: "none", height: "100%", cursor: "pointer", aspectRatio: 12 / 16, borderRadius: 10, objectFit: "contain" }}
                        onError={(i) => (i.target.src = require('../../images/pblue-min.webp'))}
                        onClick={() => null}
                        alt={"Premsi Post" + post[1]?.description}
                      />
                      :
                      <VideoPlayer src={post[1]?.media[0]} />
                    }
                  </div>

                  <div className='postinfo'>
                    <div className='postinnerinfo'>

                      <div className='postinteractioninfo'>
                        <BsFillHeartFill size={22} color='white' alt='like post' />
                        <p>{formatNumber(post[1]?.likesCount)}</p>
                      </div>

                      <div className='postinteractioninfo'>
                        <FaComment size={22} color='white' alt='comment post' />
                        <p>{formatNumber(post[1]?.commentsCount)}</p>
                      </div>

                      <button className='sharebtn' onClick={() => (navigator.clipboard.writeText("www.premsi.com/post?id=" + post[0]), setcopiedlink(true))}>
                        {
                          copiedlink
                            ?
                            <p>Copied ✅</p>
                            :
                            <>
                              <FaShare size={22} color='white' alt='share post' />
                              <p>Share</p>
                            </>
                        }
                      </button>

                      {postuserinfo === null
                        ?
                        null
                        :
                        <TwitterShareButton
                          onLoad={function noRefCheck() { }}
                          options={{
                            buttonHashtag: undefined,
                            screenName: undefined,
                            size: 'large',
                            text: "See this new Premsi post from " + postuserinfo?.displayName + " 🔥🧿",
                            //via: 'saurabhnemade'
                          }}
                          placeholder={<div style={{ backgroundColor: 'transparent', color: 'white', margin: 10, padding: 10, textAlign: "center", fontSize: 11 }}>loading x</div>}
                          url={`https://www.premsi.com/post?id=${post[0]}`}
                        />
                      }

                    </div>
                  </div>

                  <h2 className='belowuserinfo'>To chat, follow, like, comment etc. with {postuserinfo?.displayName} please <a href="app" style={{ color: "white" }}>download</a> our App.</h2>
                </>
              )
          )
        }
      </div>

      <Footer />

    </div>
  )
}

export default PostScreen



import React, { useState } from 'react'
import Footer from '../../../components/Footer'
import { useNavigate } from 'react-router-dom'
import './index.css'
import { Helmet } from 'react-helmet'
import NavForNotHome from '../../../components/Nav/NavForNotHome';

function PrivacyPolicy() {
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi - Privacy Policy❤️" />
                <meta property="og:description" content="Premsi | Privacy Policy . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/privacypolicy" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
                <meta property="og:site_name" content="Premsi - sell digital content" />
                <link rel="canonical" href="https://premsi.com/privacypolicy" />

                <meta name="description" content="Premsi | Privacy Policy . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <title>Premsi - Privacy Policy</title>
            </Helmet>
            <NavForNotHome />
            <div className='outerlegaldiv' style={{ marginTop: 20 }}>
                <div className='innerlegaldiv'>

                    <h1>Privacy Policy - Premsi</h1>

                    <h2>1. Managing Your Data Preferences: </h2>
                    <p>
                        You have the option to manage, review, update, or modify your Personal Information directly through your account settings. We may occasionally send you emails about our Services, such as updates about your account or product information. We will only send marketing emails if you have provided your consent to receive them, and you can unsubscribe from such emails at any time. However, certain essential communications related to the Services, such as important updates to our Terms of Use or Privacy Policy, cannot be opted out. For any questions about your account information, feel free to contact us at contact@premsi.com.
                    </p>

                    <h2>2. Information We Collect </h2>
                    <p>
                        Personal Information:
                        "Personal Information" refers to any data that could identify an individual user, either alone or when combined with other information. Examples of Personal Information include username, email address, phone number, and photographs. We collect the following types of Personal Information:
                        •  Contact Information: First and last name, phone number, email address, shipping address, and billing address.
                        •  Other Identifying Information: IP address, social media handles (if you opt in to cross- posting to other social networks or to verify your account), and a copy of your photo/government ID (for account verification purposes, which we delete after verification or non-verification).
                        •  Payment Information: Credit card and debit card numbers.
                        •  Device Type: Your device type (e.g., iPhone or Android phone model) for sending push notifications (if you opt in).
                        •  Geolocation Data: Location data when you tag a post with a location.
                        •  Internet or Electronic Activity: Anonymized browsing and click history on our app for analytical purposes.
                        •  Visual, Audio, and Text Information: Photos, videos, and text content you post on our app, which we store only for display purposes and not for any other use.

                        You may be asked to provide Personal Information during registration or while using our Services.
                        Additionally, for security purposes, we collect device identifiers, operating system, log data, and general geographic location based on IP address or device location using security tools such as anti-fraud and hacking detection.
                    </p>


                    <h2>3. Categories of Use:</h2>
                    <p>
                        We use your Personal Information for the following purposes:
                        •  Transactional Purposes: Opening your account, displaying your daily usage within the app, allowing you to find and connect with others, and sharing content on the app.
                        •  Analytical Purposes: Analyzing preferences, trends, and statistics to improve our app and Services.
                        •  Marketing Purposes: Informing you about new products, services, opportunities, and offers (you can opt-out).
                        •  Maintenance and Improvement: Providing and maintaining functionality, improving our Services, handling customer service requests, and diagnosing technical issues.
                        •  Security and Fraud Prevention: Protecting our app, Services, company, and users from fraud, theft, and illegal activities.
                    </p>

                    <h2>4. Sources of Personal Information: </h2>
                    <p>
                        We collect Personal Information from the following sources:
                        •  Directly from You: Contact and identifying information when you register or use our Services.
                        •  Third Parties: Identifying information from third-party services if you choose to link your accounts or cross-post content.
                        •  App Activity: Anonymized analytical data from your actions within the app.
                    </p>

                    <h2>5. Sharing Information with Third Parties: </h2>
                    <p>
                        We may engage trustworthy Service Providers to offer certain services, such as hosting, data storage, payment processing, and marketing. We provide these Service Providers with necessary information solely for performing these services and ensure they follow appropriate security measures.
                        We may also share IP addresses with third parties for certain app features, such as tagging photos with location data. The third parties involved are Google and (more maybe in future updates), and you can refer to their privacy policies for more information.
                        In the event of a merger, acquisition, or sale, we may disclose or transfer Personal Information and Usage Data related to the transaction.
                        We may cooperate with government and law enforcement officials to comply with legal requests, protect against fraud and illegal activities, or enforce the law.
                    </p>


                    <h2>6. Cookies and Anonymous Identifiers: </h2>
                    <p>
                        We do not use cookies. We might use cookies and similar technologies in the future to temporarily store anonymous identifiers and user information for remembering settings and analyzing app usage to improve our Services. You will be able to adjust your web browser settings to refuse cookies, but it may affect certain features.
                    </p>

                    <h2>7. Personal Information You Make Available to Others When using our Services:</h2>
                    <p>
                        Certain Personal Information you voluntarily disclose, like comments or photos, may become publicly available based on your privacy settings.
                    </p>

                    <h2>8. Inviting Contacts to Join PREMSI: </h2>
                    <p>
                        We offer the "Invite Friends" and "Add Contacts" features to expand your network. When using these features, you grant permission to access and collect phone numbers from your contact list (only phone numbers, not names or other details). You can turn off this access in your profile settings.
                    </p>

                    <h2>9. Advertising and Online Tracking:</h2>
                    <p>
                        We do not have advertising on our app or Services, but we may allow third-party companies to collect certain information for advertising purposes when interacting with them through our Services.
                    </p>

                    <h2>10. Security: </h2>
                    <p>
                        We employ physical, electronic, and procedural safeguards to protect your data but cannot guarantee 100% security. You use our Services at your own risk.
                    </p>

                    <h2>11. Links: </h2>
                    <p>
                        Our app and Services may contain links to third-party websites or services. We are not responsible for their privacy practices; you access them at your own risk.
                    </p>

                    <h2>12. Children's Privacy: </h2>
                    <p>
                        Our app and Services are for users aged 13 and older. We do not knowingly collect Personal Information from children under 13. If we discover such data without parental consent, we will delete it promptly.                     </p>

                    <h2>13. Location of Processing: </h2>
                    <p>
                        Your Personal Information and communications may be processed and maintained on servers outside your region. By using our Services, you agree to the transfer and processing of your data as outlined in this Privacy Policy.                     </p>

                    <h2>14. EU and Switzerland Residents: </h2>
                    <p>
                        If you are in the EU or Switzerland, you have specific rights regarding your Personal Information, including access, rectification, erasure, restriction, portability, objection, and the right to withdraw consent. To exercise these rights, contact us through the app or at contact@premsi.com.                     </p>

                    <h2>15. How Long We Keep Your Information:
                    </h2>
                    <p>After account termination, we may retain your profile information and user content for a reasonable period for backup, archival, or audit purposes. We will comply with data protection laws regarding data retention.
                    </p>

                    <h2>16. California Consumer Privacy Rights:
                    </h2>
                    <p>
                        California residents have certain privacy rights under CCPA. You can request information about the Personal Information we collected, used, and disclosed, and also request the deletion of your Personal Information. To exercise these rights, submit your request through the app or email us at contact@premsi.com. We do not discriminate based on CCPA Rights requests.
                    </p>

                    <h2>17. Privacy Policy Changes:
                    </h2>
                    <p>
                        We may update this Privacy Policy from time to time, and the revised version will be posted on our website. By continuing to use our Services, you consent to the updated Privacy Policy.
                    </p>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default PrivacyPolicy

import React, { useEffect } from 'react'
import Banner from '../../components/Banner/Banner'
import Nav from '../../components/Nav/Nav'
import Rows from '../../components/Rows/Rows'
import './HomeScreen.css'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer'
import { auth } from '../../firebase'
import VerifyEmailComponent from '../../components/rememberverifyemail'
import { Helmet } from 'react-helmet'

function HomeScreen() {
  const navigate = useNavigate()

  return (
    <div className='homeScreen'>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Premsi - Easily sell your digital content❤️" />
        <meta property="og:description" content="Premsi | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
        <meta property="og:url" content="https://premsi.com" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
        <meta property="og:site_name" content="Premsi - sell digital content" />

        <title>Premsi - Easily sell your digital content❤️</title>
        <meta name="description" content="Premsi | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
        <link rel="canonical" href="https://premsi.com" />
      </Helmet>
      {/* NavBar */}
      <Nav />
      {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}
      {/* Banner */}
      <Banner />
      {/* rows different rows to show different things */}
      <Rows title="Trending" isLargeRow={true} />
      <Rows title="Feed" />
      <Rows title="Premium" />

      <Footer />
    </div>
  )
}

export default HomeScreen

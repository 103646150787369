import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './PremiumMessageBought.css';
import Nav from '../../components/Nav/Nav'
import { Oval, MagnifyingGlass } from 'react-loader-spinner'
import { FaComment, FaShare } from 'react-icons/fa'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import Footer from '../../components/Footer';
import { auth, functions } from '../../firebase';
import { Helmet } from 'react-helmet';
import { TwitterShareButton } from 'react-twitter-embed';
import { HiOutlineLockClosed } from "react-icons/hi";
import { httpsCallable } from 'firebase/functions';
import { pdfjs, Document, Page } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import VideoPlayerBoughtPM from '../../components/VideoPlayer/VideoPlayerBoughtPM';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const resizeObserverOptions = {};

const maxWidth = 800;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

//see which one google indees and which notif there are dofferences
//check faq pmitem app profile
function PremiumMessageBought() {
  // https://premsi.com/boughtpm?email=vanaprogr@gmail.com&id=8XNWZWyYVJ1KAJhpw2m4
  // http://localhost:3000/boughtpm?email=antoniovanacoree@gmail.com&id=8XNWZWyYVJ1KAJhpw2m4

  // to allow the pdf getting read need to allow cors : https://stackoverflow.com/questions/37760695/firebase-storage-and-access-control-allow-origin
  // changed for premsi in https://console.cloud.google.com/cloudshelleditor?referrer=search&authuser=1&cloudshell=true&project=premsicom-accb2
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const [pmitem, setpmitem] = useState(null);

  const [retrievedpm, setretrievedpm] = useState(null);
  const [retrievedpmerrorlogin, setretrievedpmerrorlogin] = useState(false);

  const [loading, setloading] = useState(false);
  const [copiedlink, setcopiedlink] = useState(false);
  const [showpp, setshowpp] = useState(false);

  const [email, setemail] = useState("");
  const [isemail, setisemail] = useState(true);
  const [pw, setpw] = useState("");
  const [loadingerror, setsetloadingerror] = useState(false);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();

  // to show all premiumposts in an array
  const [arrayofmedia, setarrayofmedia] = useState([]);

  const onResize = useCallback((entries) => {
    const [entry] = entries;
    //console.log("resized", entry.contentRect.width)

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  useEffect(() => {
    let pmid = searchParams.get("id")
    let email = searchParams.get("email")
    if (pmid === null || pmid === undefined || pmid === "" || email === null || email === undefined || email === "") {
      setpmitem("pmnotexists")
    } else {
      //console.log("has info")
      const checkpmbackend = httpsCallable(functions, 'checkifpmexistsbe');
      checkpmbackend({ pmid: pmid, email: email })
        .then((result) => {
          // Read result of the Cloud Function.
          //console.log("resulttt checkpmbackend", result.data)
          setpmitem(result.data)
        })
        .catch((error) => {
          // Getting the Error details.
          const code = error.code;
          const message = error.message;
          const details = error.details;
          //console.log("errorcloudfunction", error)
          //if response is error let user know with alert or so
          setsetloadingerror("there is an error on baclend")
        });
    }
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    //console.log("this isnumPages", numPages)
    setNumPages(numPages);
  }

  useEffect(() => {
    // console.log("email", email)
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      // this is a valid email address
      setisemail(true)
    }
    else {
      // invalid email, maybe show an error to the user.
      setisemail(false)
    }
  }, [email]);

  const unlock = () => {
    console.log("unlock it", email, pw, pmitem.id)
    setloading(true)
    setretrievedpmerrorlogin(false)

    const checkpmbackend = httpsCallable(functions, 'retrievePMboughtwithoutaccountandcheck');
    checkpmbackend({ email: email, pw: pw, boughtdocid: pmitem.id })
      .then((result) => {
        // Read result of the Cloud Function.
        //console.log("resulttt checkpmbackend", result.data)
        if (result.data === "credentialwrong") {
          setretrievedpmerrorlogin(true)
          setloading(false)
        } else {
          console.log("resulttt checkpmbackend", result)
          setretrievedpmerrorlogin(false)
          setretrievedpm(result.data)
          setloading(false)
        }
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        //console.log("errorcloudfunction", error)
        //if response is error let user know with alert or so
        setsetloadingerror("there is an error on baclend")
        setloading(false)
      });
  }

  useEffect(() => {
    console.log("retrievedpm", retrievedpm?.data.pmi.type, retrievedpm?.data.pmi.media, retrievedpm?.data.pmi.media[0][1])
  }, [retrievedpm]);

  useEffect(() => {
    console.log("restrievedpm useeffect", retrievedpm)
    let i = 0
    let farray = []

    if (retrievedpm === null) {
      return
    } else {
      while (i < retrievedpm?.data.pmi.amountfiles) {
        //console.log("this is while", i, route.params.item.item.media[i])
        farray.push(retrievedpm?.data.pmi.media[i])
        i++
      }
      setarrayofmedia(farray)
    }

  }, [retrievedpm]);

  return (
    <div className='postscreen'>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Premsi - Easily sell your digital content❤️" />
        <meta property="og:description" content="Premsi | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
        <meta property="og:url" content="https://premsi.com/boughtpm" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
        <meta property="og:site_name" content="Premsi - sell digital content" />
        <link rel="canonical" href="https://premsi.com/boughtpm" />

        <title>Premsi - See Your Bought Premium Message❤️</title>
        <meta name="description" content="Premsi | See Your Bought Premium Message . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />

        <meta name="twitter:title" content="Premsi" />
        <meta name="twitter:description" content={`Premsi.com Premium Message`} />
        <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Nav />

      <div className='postscreen__body'>

        {pmitem === null ?
          <div>
            <Oval
              height={40}
              width={40}
              color="#009FFD"
              wrapperStyle={{ marginTop: 20, flex: "display", alignItems: "center", justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="white"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
            <p style={{ marginBottom: 3, textAlign: "center" }}>Loading ...</p>
            <p style={{ textAlign: "center", fontSize: 12 }}>If this takes too long please refresh this window.</p>
          </div>
          :
          (
            pmitem === "pmnotexists"
              ?
              <div>
                <MagnifyingGlass
                  height={40}
                  width={40}
                  color="#009FFD"
                  wrapperStyle={{ marginTop: 20, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="white"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <p style={{ marginBottom: 3, textAlign: "center" }}>No Premium Message...</p>
                <p style={{ textAlign: "center", fontSize: 12, marginTop: 20 }}>It seems that you have a wrong link and this Premium Message doesn't exist</p>
                <p style={{ textAlign: "center", fontSize: 12 }}>Please recheck your link.</p>
                <div style={{ display: "flex", alignItems: "center", marginTop: 20, justifyContent: "center", width: "100%", marginBottom: 5 }}>
                  <button onClick={() => navigate("/")} style={{ fontSize: 14, fontWeight: "bold", paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10, backgroundColor: "#009FFD", color: "white", borderWidth: 0 }}>Explore others</button>
                </div>
              </div>
              :
              <>
                <div className='postscreen__infopm'>

                  <div style={{ width: "100%", display: "flex", marginBottom: 10, alignItems: "center", justifyContent: "center" }}>
                    <h1 style={{ color: "black", fontSize: 17 }}>Premsi Premium Message:</h1>
                  </div>

                  <div className='postscreen__details'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <button onClick={() => navigate("/user?id=" + pmitem?.data.seller)} className='useremail'> by: {pmitem?.data.sellerdisplayname}</button>
                    </div>
                  </div>
                </div>

                {retrievedpm === null || retrievedpm === undefined
                  ?
                  <div className='postidvouter'>
                    <div className='postdivpm'>
                      <HiOutlineLockClosed size={23} color='#009FDD' alt='private Premsi premium message' />
                    </div>
                  </div>
                  : (retrievedpm?.data.pmi.type === "image" ?
                    //here slider

                    <div className='uploadfileouterdiv' >
                      {arrayofmedia.map((mappedimage, i) =>
                        <div style={{ height: 350, aspectRatio: 12 / 16, borderRadius: 20, backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <img
                            loading="lazy"
                            key={mappedimage[1]}
                            src={mappedimage[1]}
                            style={{ pointerEvents: "none", height: "100%", aspectRatio: 12 / 16, borderRadius: 20, objectFit: "contain" }}
                            onError={(i) => (i.target.src = require('../../images/pblue-min.webp'))}
                            onClick={() => null}
                            alt={"Premsi Premium Message" + retrievedpm?.data.pmi.description}
                          />
                        </div>
                      )}
                    </div>
                    :
                    (retrievedpm?.data.pmi.type === "video"
                      ?
                      //here slider
                      <div className='uploadfileouterdiv' >
                        {arrayofmedia.map((mappedvideo, i) =>
                          <div style={{ height: 350, aspectRatio: 12 / 16, overflow: "hidden", borderRadius: 20, backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <VideoPlayerBoughtPM style={{ pointerEvents: "none", height: "100%", backgroundColor: "white", aspectRatio: 12 / 16, borderRadius: 20 }} src={mappedvideo[0]} />
                          </div>
                        )}
                      </div>

                      :
                      <div style={{ pointerEvents: "none" }} className="Example__container" ref={setContainerRef}>
                        <div style={{ backgroundColor: "#009FFD", paddingLeft: 20, marginBottom: 10, display: "flex", alignItems: "center", justifyContent: "center", paddingRight: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 15 }}>
                          <p style={{ color: "white", fontSize: 14 }}>Total {numPages} pages</p>
                        </div>

                        <div className="Example__container__document">
                          <Document options={options} style={{ width: "80%", height: "80%" }} onLoadSuccess={onDocumentLoadSuccess} file={retrievedpm?.data.pmi.media}>
                            {
                              Array.apply(null, Array(numPages))
                                .map((x, i) => i + 1)
                                .map((page) => {
                                  return (
                                    <Page
                                      pageNumber={page}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                      width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                      style={{ marginBottom: 30 }}
                                    />
                                  )
                                })
                            }
                          </Document>
                        </div>
                      </div>

                      //retrievedpm?.data.pmi.media[0]
                      //https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/premiummessagepdf%2FqVe5p15eD8a4U3Tw3OMdPSsBSdh2%2Fade03a63-1020-431f-917b-410aba42826c?alt=media&token=d518e82c-0947-473e-8943-fdb65f22eb90
                    )
                  )
                }

                {retrievedpm?.data.pmi.amountfiles === null || retrievedpm?.data.pmi.amountfiles === undefined
                  ?
                  null
                  :
                  <div className='amountfilesouter'>
                    <div className='amountfilesinner'>
                      <h5 style={{ textAlign: "center" }}>{retrievedpm?.data.pmi.amountfiles} 🏞️</h5>
                    </div>
                  </div>}

                <div className='groupdescription'>
                  <div className='groupdescriptioninner'>
                    <h5 style={{ textAlign: "center" }}>{pmitem?.data.description}</h5>
                  </div>
                </div>

                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className='postinfopm'>
                    <div className='postinnerinfo'>

                      <button className='sharebtn' onClick={() => (navigator.clipboard.writeText("www.premsi.com/pm?id=" + pmitem?.data.pmid), setcopiedlink(true))}>
                        {
                          copiedlink
                            ?
                            <p>Copied ✅</p>
                            :
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                              <FaShare size={22} color='black' alt='share post' />
                              <p style={{ color: "black", textAlign: "center" }}>Share</p>
                            </div>
                        }
                      </button>
                      {pmitem?.data.pmid === null || pmitem?.data.pmid === undefined
                        ? null : <TwitterShareButton
                          onLoad={function noRefCheck() { }}
                          options={{
                            buttonHashtag: undefined,
                            screenName: undefined,
                            size: 'large',
                            text: "See this new Premsi Premium Message 🔥🧿",
                            //via: 'saurabhnemade'
                          }}
                          placeholder={<div style={{ backgroundColor: 'transparent', color: 'white', margin: 10, padding: 10, textAlign:"center",fontSize:11 }}>loading x</div>}
                          url={`https://www.premsi.com/pm?id=${pmitem?.data.pmid}`}
                        />}
                    </div>
                  </div>
                </div>

                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {loading
                    ?
                    <div className='buypmbtn'>
                      <Oval
                        height={40}
                        width={40}
                        color="white"
                        wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    </div>
                    :
                    (retrievedpm === null || retrievedpm === undefined ?
                      <div className='buypmbtn'>
                        <p style={{ color: "white", marginBottom: 10, fontSize: 14, fontWeight: "bold", borderRadius: 10, paddingTop: 10, paddingBottom: 10, paddingRight: 20, paddingLeft: 20, textAlign: "center", backgroundColor: "rgba(255,255,255,0.4)" }}>Unlock your bought message ❤️ </p>
                        <input style={{ border: "none", color: "white", fontSize: 15, borderRadius: 20, height: 40, textAlign: "center", width: 220, backgroundColor: "rgba(255,255,255,0.4)" }} type="email" placeholder='Your E-Mail' onChange={(e) => setemail(String(e.target.value).trim())} />
                        <input style={{ border: "none", marginTop: 15, marginBottom: 10, color: "white", fontSize: 15, borderRadius: 20, height: 40, textAlign: "center", width: 220, backgroundColor: "rgba(255,255,255,0.4)" }} type="text" placeholder='Password' onChange={(e) => setpw(String(e.target.value).trim())} />

                        {isemail ? null : <p className='noemail'>Please insert your e-mail and password to unlock</p>}

                        <div onClick={() => unlock()} className='buypmbtnendpp'>
                          <p style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Unlock 🎉</p>
                        </div>

                        {loadingerror && <p style={{ color: "red" }}>There was an error please retry.</p>}

                        {retrievedpmerrorlogin && <p style={{ color: "red" }}>Your login credentials are wrong 😢 </p>}

                      </div>
                      :
                      <div className='buypmbtn'>
                        <p style={{ color: "white", textAlign: "center", fontWeight: "bold" }}>Here is your Premium Message 🎉</p>
                      </div>)
                  }
                </div>

                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <h2 className='belowuserinfo'>Download our <a href="app" style={{ color: "black" }}>App</a> to make the buying easier next time.</h2>
                </div>

              </>
          )
        }
      </div>

      <Footer />

    </div>
  )
}

export default PremiumMessageBought



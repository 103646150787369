import React, { useEffect, useState } from 'react'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import $ from 'jquery'
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '../../firebase';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Oval } from 'react-loader-spinner';

const StripeBuyImmediatelyBtn = ({ pmitem, price }) => {
    const [pperror, setpperror] = useState(null);
    const [errorMessage, seterrorMessage] = useState(false);
    const [em, setem] = useState("");
    const [elementerror, setelementerror] = useState(false);
    const [loading, setloading] = useState(false);
    const [successpayment, setsuccesspayment] = useState(false);

    const stripe = useStripe()
    const elements = useElements()

    useEffect(() => {
        try {
            let e = elements.getElement(PaymentElement)
            e.on('loaderror', function (event) {
                // Handle loaderror event
                console.log("loaserr", event)
            });
        } catch (error) {
            console.log(error)
        }
    }, [elements]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        seterrorMessage(false)
        setloading(true)
        setsuccesspayment(false)
        setelementerror(false)
        setpperror(null)

        if (elements == null || stripe == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError?.message) {
            // Show error to your customer
            console.log(submitError.message);
            setelementerror(true)
            setloading(false)
            return;
        }
        console.log("all good")
        await auth.currentUser.getIdToken(true).then((tok) => {
            const stripeclientsecret = httpsCallable(functions, 'getstripeclientsecretforpmloggedin');
            stripeclientsecret({ currency: "usd", amount: price, idtoken: tok, user: auth.currentUser.uid })
                .then(async (res) => {
                    // Read result of the Cloud Function.
                    console.log("resulttt checkorderbackend", res.data.client_secret)

                    if (res === "error") {
                        setpperror("there is an error on baclend")
                        setloading(false)
                    }

                    //this below to cinform payment
                    const { paymentIntent, error } = await stripe.confirmPayment({
                        //`Elements` instance that was used to create the Payment Element
                        elements,
                        clientSecret: res.data.client_secret,
                        confirmParams: {
                            return_url: `${window.location.origin}`, //hier richtige url zum checken
                        },
                        redirect: "if_required"
                    });
                    if (error && error.decline_code === "insufficient_funds") {
                        console.log("There was an error saving the card", error)
                        seterrorMessage(true)
                        setem("You have insufficient funds. Please retry")
                        setloading(false)
                        return
                    } else if (error && error.code === "card_declined") {
                        console.log("There was an error saving the card", error)
                        seterrorMessage(true)
                        setem("Your card was declined. Please retry")
                        setloading(false)
                        return
                    } else if (error && error.code === "expired_card") {
                        console.log("There was an error saving the card", error)
                        seterrorMessage(true)
                        setem("Your card expired. Please retry with another one.")
                        setloading(false)
                        return
                    } else if (error && error.code === "incorrect_cvc") {
                        console.log("There was an error saving the card", error)
                        seterrorMessage(true)
                        setem("Your CVC code is wrong.")
                        setloading(false)
                        return
                    } else if (error && error.code === "incorrect_number") {
                        console.log("There was an error saving the card", error)
                        seterrorMessage(true)
                        setem("Your card number is incorrect. Please retry")
                        setloading(false)
                        return
                    } else if (error) {
                        console.log("There was an error saving the card", error)
                        seterrorMessage(true)
                        setem("Your card transaction didn´t work. Please retry")
                        setloading(false)
                        return
                    } else {
                        console.log("this is paymentIntent", paymentIntent)
                        // send paymentIntent to backend to buy PM but not logged in 

                        // checkpaypalboughtpmmessagecorrectly backend funx to add premiummessage to profile

                        const checkorderbackend = httpsCallable(functions, 'updatepamyentintentforpmloggedin');
                        checkorderbackend({ user: auth.currentUser.uid, idtoken: tok, paymentintent: JSON.stringify(paymentIntent), pmid: pmitem[0], pmitem: JSON.stringify(pmitem[1]) })
                            .then((result) => {
                                // Read result of the Cloud Function.
                                console.log("resulttt checkorderbackend", result)
                                if (result.data === "success") {
                                    //console.log("worked buying")
                                    // if response is success
                                    setpperror(null)
                                    setsuccesspayment(true)
                                    setloading(false)
                                    //refresh user account or financials
                                } else {
                                    //if response is error let user know with alert or so
                                    setpperror("there is an error on baclend")
                                    setloading(false)
                                }
                            })
                            .catch((error) => {
                                // Getting the Error details.
                                const code = error.code;
                                const message = error.message;
                                const details = error.details;
                                //console.log("errorcloudfunction", error)
                                //if response is error let user know with alert or so
                                setpperror("there is an error on baclend")
                                setloading(false)
                            });
                    }
                })
                .catch((error) => {
                    const code = error.code;
                    const message = error.message;
                    console.log("error", code, message)
                });
        })
    };

    return (
        <>

            {successpayment
                ?
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 15, borderRadius: 20, justifyContent: "center", backgroundColor: "green" }}>
                    <p style={{ color: "white", marginBottom: 7, fontSize: 14 }}>You successfully bought this premium message.</p>
                    <p style={{ color: "white", marginBottom: 7, fontSize: 14 }}>The Payment will be verified on our end. This can take some seconds 🎉</p>
                    <p style={{ color: "white", marginBottom: 7, fontSize: 14 }}>Download the Premsi <a href="app" style={{ color: "white" }}>App</a> to view it in your profile 🎉</p>
                </div>
                :
                <PaymentElement />}

            {loading
                ?
                <Oval
                    height={40}
                    width={40}
                    color="#009FFD"
                    wrapperStyle={{ flex: "display", alignItems: "center", justifyContent: "center" }}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="white"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                />
                :
                (successpayment ? null : <button onClick={(e) => handleSubmit(e)} style={{ backgroundColor: "#85cbf4", color: "white", border: "none", marginTop: 10, height: 30, width: 100, borderRadius: 20, fontSize: 15 }} disabled={!stripe}>
                    Pay
                </button>)
            }

            {errorMessage && <div style={{ color: "red", fontSize: 14, marginTop: 10 }}>{em}</div>}
            {elementerror && <div style={{ color: "red", fontSize: 14, marginTop: 10, textAlign: "center" }}>There has been an error. Please retry your payment information.</div>}
            {pperror ? <p style={{ textAlign: "center", color: "red" }}>There was an error with the payment. Please retry.</p> : null}
        </>
    )
}

export default StripeBuyImmediatelyBtn


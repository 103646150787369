import React, { useRef, useState } from 'react'
import './CreateContentScreen.css'
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { auth } from '../../firebase';
import { Helmet } from 'react-helmet';
import FAQcomponent from '../../components/FAQcomponent/FAQcomponent';

function CreateContentScreen() {
    const [featureshow, setfeatureshow] = useState(1);
    const [showearnings, setshowearnings] = useState(1);

    const myRef = useRef(null)
    const navigate = useNavigate()

    return (
        <>
            <Helmet>

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi - Our App❤️" />
                <meta property="og:description" content="Premsi | Sell and market digital content in seconds. Download the Premsi App for more features. Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/sell" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
                <meta property="og:site_name" content="Premsi - sell digital content" />

                <title>Premsi - Sell digital content in minutes❤️</title>
                <meta name="description" content="Premsi | Post and earn on Premsi. Download the Premsi App for more features. Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <link rel="canonical" href="https://premsi.com/sell" />
            </Helmet>
            <Nav />
            {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}
            <div className='loginscreen_app' ref={myRef}>
                <div style={{ width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.65)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className='loginscreen__body'>
                        <div style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                            <h1 style={{ color: "white", marginBottom: 10, width: "100%", textAlign: "center" }}>Sell with Premsi in 1 minute on the App 🎉</h1>
                            <h2 style={{ color: "white", marginBottom: 15, width: "80%", textAlign: "center" }}>On our App you can easily create your Premium Content in form of pictures/videos/PDFs and paid groups!</h2>
                            <h3 style={{ color: "white", marginBottom: 5, width: "80%", textAlign: "center" }}>Explore more of our exciting features in our App!<br /> Soon you will be able to create Premium Content on our Website</h3>
                            <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-around", marginBottom: 20, marginTop: 10 }}>
                                <a href="https://apps.apple.com/app/apple-store/id6446628909" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} target='_blank'><img loading="lazy" src={require('../../images/appstore.webp')} style={{ cursor: "pointer", height: 50, borderRadius: 5 }} alt="Premsi.com App store link" /></a>
                                <a href="https://play.google.com/store/apps/details?id=com.Premsi.app" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} target='_blank'><img loading="lazy" src={require('../../images/googleplay.webp')} style={{ cursor: "pointer", height: 50, borderRadius: 5, borderColor: "black", borderWidth: 1, borderStyle: "solid" }} alt="Premsi.com Google play store link" /></a>
                            </div>
                            <div onClick={() => navigate("/createpremiummessage")} style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, paddingTop: 15, paddingBottom: 15, width: 250, marginBottom: 30, backgroundColor: "#009ffd" }}>
                                <p style={{ fontWeight: "bold", color: "white" }}>Sell your content now 🎉</p>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <div className='whatispremsimainmain'>
                <div className='whatispremsitop' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                    <p style={{ fontSize: 23, fontWeight: "bold", color: "black" }}>What is Premsi? </p>
                    <img loading="lazy" src={require('../../images/pblue-min.webp')} style={{ marginLeft: 10, height: 25, width: 25, borderRadius: 15 }} alt="Premsi.com logo app icon" />
                </div>

                <p className='premsiexplanationp'>Premsi is the social media where you can see the more private side of your favorite creator 🔥</p>

                <div className='whatispremsimainhome'>
                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>⏱</p>
                        <p>1 minute to create an Account and start to Sell / Buy or both 🚀</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>🏦</p>
                        <p>Premsi is free</p>
                        <p>No monthly subscription</p>
                        <p>85% daily payout</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>⭐️</p>
                        <p>Exploring / Marketing / Selling in an one-in-all App + Website</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>🔥</p>
                        <p>Fans can buy your content without logging in</p>
                    </div>
                </div>

                {/* here have social media icons */}

                <div className='socialbar' >
                    <p style={{ fontSize: 15, opacity: 0.75 }}>seen on</p>
                    <div className='innersocialbigger' >
                        <img className='socialbarimage' alt='Hacker news logo' src={require('../../images/socialforbar/hn.webp')} />
                        <img className='socialbarimage' alt='Twitter X logo' src={require('../../images/socialforbar/x.webp')} />
                    </div>
                    <div className='innersocialbigger' >
                        <img className='socialbarimage' alt='Tiktok logo' src={require('../../images/socialforbar/tt.webp')} />
                        <img className='socialbarimage' alt='Product Hunt logo' src={require('../../images/socialforbar/producthunt.webp')} />
                    </div>
                    <div className='innersocialsmaller' >
                        <img className='socialbarimage' alt='Instagram logo' src={require('../../images/socialforbar/ig.webp')} />
                    </div>
                </div>

                {/*Here start explained our features */}
                <div style={{ display: "flex", marginTop: 30, alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
                    <p style={{ fontSize: 20, fontWeight: "bold", color: "black" }}>8+ benefits of Premsi </p>
                    <img loading="lazy" src={require('../../images/pblue-min.webp')} style={{ marginLeft: 5, height: 25, width: 25, borderRadius: 15 }} alt="Premsi.com logo app icon" />
                </div>

                <div style={{ display: "flex", marginTop: 0, alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                    <p style={{ fontSize: 15, fontWeight: "bold", color: "black" }}>4+ different income streams with Premsi</p>
                </div>

                <div className='featureouterdiv'>
                    <div className='featureinnerdiv'>
                        <div className='featurebtndiv'>
                            <div onClick={() => setfeatureshow(1)} className='featurebtn' style={{ backgroundColor: featureshow === 1 ? "#009FFD" : null }}>Explore 🚀</div>
                            <div onClick={() => setfeatureshow(2)} className='featurebtn' style={{ backgroundColor: featureshow === 2 ? "#009FFD" : null }}>Buy 🔥</div>
                        </div>
                        <div className='featurebtndiv'>
                            <div onClick={() => setfeatureshow(3)} className='featurebtn' style={{ backgroundColor: featureshow === 3 ? "#009FFD" : null }}>Sell ✍️</div>
                            <div onClick={() => setfeatureshow(4)} className='featurebtn' style={{ backgroundColor: featureshow === 4 ? "#009FFD" : null }}>Groups 👥</div>
                        </div>
                    </div>
                    <div className='featureinnerdiv'>
                        <div className='featurebtndiv'>
                            <div onClick={() => setfeatureshow(5)} className='featurebtn' style={{ backgroundColor: featureshow === 5 ? "#009FFD" : null }}>Content 🏞</div>
                            <div onClick={() => setfeatureshow(6)} className='featurebtn' style={{ backgroundColor: featureshow === 6 ? "#009FFD" : null }}>Marketing 🍿</div>
                        </div>
                        <div className='featurebtndiv'>
                            <div onClick={() => setfeatureshow(7)} className='featurebtn' style={{ backgroundColor: featureshow === 7 ? "#009FFD" : null }}>30sec rule ⏱</div>
                            <div onClick={() => setfeatureshow(8)} className='featurebtn' style={{ backgroundColor: featureshow === 8 ? "#009FFD" : null }}>App 👩‍💻</div>
                        </div>
                    </div>
                </div>

                <div className='featureexplanationmaindiv'>
                    {featureshow === 1 ?
                        <div className='featureexplanationinnerdiv'>
                            <div className='featureexplanationhalfdiv'>
                                <img loading="lazy" style={{ width: "90%" }} src={require('../../images/homepresentationphotos/1.webp')} alt="Premsi.com feed to explore" />
                            </div>
                            <div className='featureexplanationhalfdiv'>
                                <h3>Explore content on our App and our Website 🚀</h3>
                                <h4>Through the sharing of normal posts you can market your premium messages or your groups!</h4>
                            </div>
                        </div>
                        :
                        (featureshow === 2 ?
                            <div className='featureexplanationinnerdiv'>
                                <div className='featureexplanationhalfdiv'>
                                    <h3>Easily buy Premium Messages ❤️</h3>
                                    <h4>You can buy Premium Photos/Videos/PDFs easily in the App or on the Website!</h4>
                                </div>
                                <div className='featureexplanationhalfdiv'>
                                    <img loading="lazy" style={{ width: "90%" }} src={require('../../images/homepresentationphotos/2.webp')} alt="Premsi.com easily buy premium message" />
                                </div>
                            </div>
                            : (featureshow === 3 ?
                                <div className='featureexplanationinnerdiv'>
                                    <div className='featureexplanationhalfdiv'>
                                        <img loading="lazy" style={{ width: "90%" }} src={require('../../images/homepresentationphotos/3.webp')} alt="Premsi.com easily create and buy premium message" />
                                    </div>
                                    <div className='featureexplanationhalfdiv'>
                                        <h3>Easily Create and Sell Premium Messages 🎉</h3>
                                        <h4>Your Premium Messages can be easily bought by your fans through our App or the website and 85% goes to you!</h4>
                                    </div>
                                </div>
                                : (featureshow === 4 ?
                                    <div className='featureexplanationinnerdiv'>
                                        <div className='featureexplanationhalfdiv'>
                                            <h3>Create and Join free or paid Groups in seconds ⭐️</h3>
                                            <h4> You can easily monetize your groups and earn with monthly subscriptions or special tips. Your Premium messages can be also marketed in your group!</h4>
                                        </div>
                                        <div className='featureexplanationhalfdiv'>
                                            <img loading="lazy" style={{ width: "90%" }} src={require('../../images/homepresentationphotos/4.webp')} alt="Premsi.com Create and Join free or paid Groups" />
                                        </div>
                                    </div>
                                    : (featureshow === 5 ?
                                        <div className='featureexplanationinnerdiv'>
                                            <div className='featureexplanationhalfdiv'>
                                                <img loading="lazy" style={{ width: "90%" }} src={require('../../images/homepresentationphotos/5.webp')} alt="Premsi.com You can share free and paid photos, videos, PDFs, create groups and soon offer courses." />
                                            </div>
                                            <div className='featureexplanationhalfdiv'>
                                                <h3>Share every type of content 🏞</h3>
                                                <h4>You can share free and paid photos, videos, PDFs, create groups and soon offer courses and paid podcasts.</h4>
                                            </div>
                                        </div>
                                        : (featureshow === 6 ?
                                            <div className='featureexplanationinnerdiv'>
                                                <div className='featureexplanationhalfdiv'>
                                                    <h3>Easily market your Premium Content with Free Posts 🍿</h3>
                                                    <h4>You can link your normal posts directly to your Groups and Premium Messages. Users can access your content on every Platform without even logging in!</h4>
                                                    <h4>Easily share your free and paid content to other social media to get even more fans and customers on Premsi!</h4>
                                                </div>
                                                <div className='featureexplanationhalfdiv'>
                                                    <img loading="lazy" style={{ width: "90%" }} src={require('../../images/homepresentationphotos/6.webp')} alt="Premsi.com Easily market your Premium Content with Free Posts" />
                                                </div>
                                            </div>
                                            : (featureshow === 7 ?
                                                <div className='featureexplanationinnerdiv'>
                                                    <div className='featureexplanationhalfdiv'>
                                                        <img loading="lazy" style={{ width: "90%" }} src={require('../../images/homepresentationphotos/7.webp')} alt="Premsi.com Fans can buy Premium Messages in less than 30seconds" />
                                                    </div>
                                                    <div className='featureexplanationhalfdiv'>
                                                        <h3>Fans can buy Premium Messages in less than 30seconds ⏱</h3>
                                                        <h4>Fans are able to buy your premium content in 30 seconds without even creating an account, making the purchase process faster and easier!</h4>
                                                        <h4>Your Premium Content will be delivered in a secure way that is not shareable to others!</h4>
                                                    </div>
                                                </div>
                                                :
                                                <div className='featureexplanationinnerdiv'>
                                                    <div className='featureexplanationhalfdiv'>
                                                        <h3>Our App makes everything easier 👩‍💻</h3>
                                                        <h4>Through our App you can create Premium and normal content, create groups, manage your account and most importantly: create payouts!</h4>
                                                    </div>
                                                    <div className='featureexplanationhalfdiv'>
                                                        <img loading="lazy" style={{ width: "90%" }} src={require('../../images/homepresentationphotos/8.webp')} alt="Premsi.com Through our App you can create Premium and normal content, create groups, manage your account and most importantly: create payouts!" />
                                                    </div>
                                                </div>
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    }
                </div>
                {/*Here end explained our features */}

                {/*<button className='faq__button' onClick={() => (navigate("/faq"))}>Our FAQs</button>*/}
            </div>

            <div className='everyuserdivsecondnotlogin'>
                <div className='everyuserdivinnersecondnotlogin'>
                    <p style={{ fontSize: 24, fontWeight: "bold", color: "white" }}>Fees 💰</p>
                    <p className='priceexplanation'>And all that for 0$. No monthly subscription. No credit card required. Nothing!</p>
                    <p className='priceexplanation'>We only earn once you make money and 85% goes directly to you</p>
                </div>
            </div>

            <div className='explainappdiv_app'>
                <h2>Easy to use App 🔥</h2>
                <div className='explainappinnerdiv'>
                    <div className='explainappthirddiv'>
                        <h4 style={{ color: "black", textAlign: "center", marginBottom: 20 }}>1️⃣ The feed to explore/market</h4>
                        <img loading="lazy" className='explainappthirdimgnormal' src={require('../../images/pic1.webp')} alt="Premsi.com feed to explore" />
                    </div>

                    <div className='explainappthirddiv'>
                        <h4 style={{ color: "black", textAlign: "center", marginBottom: 20 }}>2️⃣ Buy premium messages</h4>
                        <img loading="lazy" className='explainappthirdimgnormal' src={require('../../images/premiummessage.webp')} alt="Premsi.com easily buy premium messages" />
                    </div>

                    <div className='explainappthirddiv'>
                        <h4 style={{ color: "black", textAlign: "center", marginBottom: 20 }}>3️⃣ Easily sell premium messages</h4>
                        <img loading="lazy" className='explainappthirdimg' src={require('../../images/pic2.webp')} alt="Premsi.com easily sell premium messages" />
                    </div>

                    <div className='explainappthirddiv'>
                        <h4 style={{ color: "black", textAlign: "center", marginBottom: 20 }}>4️⃣ Create free/paid subscription groups</h4>
                        <img loading="lazy" className='explainappthirdimg' src={require('../../images/groups1.webp')} alt="Premsi.com easily create free/paid subscription groups" />
                    </div>
                </div>
            </div>

            {/* FAQ section */}
            <FAQcomponent />

            {/*<div style={{ height: 150, marginBottom: 30, width: "100%", backgroundColor: "black", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <h3 style={{ color: "white", marginBottom: 10, width: "80%", textAlign: "center" }}>bar with animation that shows different earnings 🎉</h3>
                <h4 style={{ color: "white", marginBottom: 15, width: "80%", textAlign: "center" }}>grid of pics</h4>
                    </div>*/}

            <div className='referralmaindiv'>
                <div className='referralinnerdiv'>
                    <div className='referralfirstinnerdiv'>
                        <h2 style={{ color: "white", width: "85%" }}>Refer friends & receive 5% of their spendings for life 🔥</h2>
                        <h4 style={{ width: "90%" }}>Whenever they subscribe to Premsi+, tip someone or buy a premium message you'll earn.</h4>
                        <button className='referfriend__button' onClick={() => (navigate("/profile"))}>Refer a friend</button>
                    </div>

                    <div className='referralfirstinnerdiv'>
                        <img loading="lazy" style={{ height: "100%" }} src={require('../../images/referral.webp')} alt="Premsi.com referral bonus" />
                    </div>
                </div>
            </div>

            {/*<div className='explainappdivwhatcreatorsachieved'>
                <h2>What our creators achieve ❤️</h2>

                {showearnings === 1 ?
                    <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/1pm.webp')} alt="Premsi.com earnings example" />
                    : (showearnings === 2 ?
                        <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/2pm.webp')} alt="Premsi.com earnings example" />
                        : (showearnings === 3 ?
                            <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/3pm.webp')} alt="Premsi.com earnings example" />
                            : (showearnings === 4 ?
                                <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/4pm.webp')} alt="Premsi.com earnings example" />
                                :
                                <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/5pm.webp')} alt="Premsi.com earnings example" />
                            )))}

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 14 }}>
                    <div onClick={() => setshowearnings(1)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 1 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(2)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 2 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(3)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 3 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(4)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 4 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(5)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 5 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                </div>
            </div>*/}

            <Footer />
        </>
    )
}

export default CreateContentScreen

import React, { useRef, useState } from 'react'
import './ExplanationScreen.css'
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import VerifyEmailComponent from '../../components/rememberverifyemail';
import { auth } from '../../firebase';
import { Helmet } from 'react-helmet';
import FAQcomponent from '../../components/FAQcomponent/FAQcomponent';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowDropright } from 'react-icons/io'

function ExplanationScreen() {
    const [featureshow, setfeatureshow] = useState(1);
    const [showearnings, setshowearnings] = useState(1);

    const myRef = useRef(null)
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="What is Premsi ? | Sell and market digital content in seconds" />
                <meta property="og:description" content="What is Premsi ? | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/presentation" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
                <meta property="og:site_name" content="Premsi - sell digital content" />

                <title>What is Premsi ? | Sell and market digital content in seconds</title>
                <meta name="description" content="What is Premsi ? | Sell and market digital content in seconds . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <link rel="canonical" href="https://premsi.com/presentation" />
            </Helmet>
            <Nav />
            {auth.currentUser === null || auth.currentUser === undefined || auth.currentUser.emailVerified === true ? null : <VerifyEmailComponent />}
            <div style={{ marginTop: 25, marginBottom: 15, display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                <img className='topimg' loading="lazy" src={require('../../images/1pres.webp')} style={{ objectFit: "contain", borderRadius: 30 }} alt="Premsi.com presentation explanation" />
            </div>

            <div className='whatispremsimainmain'>
                <div className='whatispremsitop' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                    <p style={{ fontSize: 23, fontWeight: "bold", color: "black" }}>What is Premsi and how does it work? </p>
                    <img loading="lazy" src={require('../../images/pblue-min.webp')} style={{ marginLeft: 10, height: 25, width: 25, borderRadius: 15 }} alt="Premsi.com logo app icon" />
                </div>

                <p className='premsiexplanationp' style={{ marginTop: 30, marginBottom: 30 }}>Premsi is the social media where you can post, market and sell all your digital content in our App + Website in 30sec 🔥</p>

                {/*Here end explained our features */}
                <div onClick={() => window.open("https://www.canva.com/design/DAGGK4tnlHw/1uj8QufsOUZ6E-9nWVYquQ/edit?utm_content=DAGGK4tnlHw&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton", "_blank")} className='explanationdiv'>
                    <div className='explanationinnerdiv'>
                        <p style={{ fontSize: 20, fontWeight: "bold", color: "white", width: "75%", textAlign: "center" }}>How create a Premium Message and sell premium messages 💰</p>
                        <IoIosArrowDropright size={30} color='white' alt='verified account' />
                    </div>
                </div>

                <div onClick={() => window.open("https://www.canva.com/design/DAGMX5HRABM/WlhYireOsGOLYwMh84p8Cg/edit?utm_content=DAGMX5HRABM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton", "_blank")} className='explanationdiv'>
                    <div className='explanationinnerdiv'>
                        <p style={{ fontSize: 20, fontWeight: "bold", color: "white", width: "75%", textAlign: "center" }}>How market your Premium Messages on Premsi 🔥</p>
                        <IoIosArrowDropright size={30} color='white' alt='verified account' />
                    </div>
                </div>

                <div onClick={() => window.open("https://www.canva.com/design/DAGMX7uYiG0/NiFT64jFxWRGWpzGbIM_WQ/edit?utm_content=DAGMX7uYiG0&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton", "_blank")} className='explanationdiv'>
                    <div className='explanationinnerdiv'>
                        <p style={{ fontSize: 20, fontWeight: "bold", color: "white", width: "75%", textAlign: "center" }}>How create free and paid subscription groups on Premsi 🫂</p>
                        <IoIosArrowDropright size={30} color='white' alt='verified account' />
                    </div>
                </div>

                <div onClick={() => window.open("https://www.canva.com/design/DAGMX7vKuJQ/suqgkX2lsP3lTf_Kh4Jj_w/edit?utm_content=DAGMX7vKuJQ&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton", "_blank")} className='explanationdiv'>
                    <div className='explanationinnerdiv'>
                        <p style={{ fontSize: 20, fontWeight: "bold", color: "white", width: "75%", textAlign: "center" }}>How create custom in chat Premium Messages 🎉</p>
                        <IoIosArrowDropright size={30} color='white' alt='verified account' />
                    </div>
                </div>


            </div>

            {/* FAQ section */}
            <FAQcomponent />

            <div className='referralmaindiv'>
                <div className='referralinnerdiv'>
                    <div className='referralfirstinnerdiv'>
                        <h2 style={{ color: "white", width: "85%" }}>Refer friends & receive 5% of their spendings for life 🔥</h2>
                        <h4 style={{ width: "90%" }}>Whenever they subscribe to Premsi+, tip someone or buy a premium message you'll earn.</h4>
                        <button className='referfriend__button' onClick={() => (navigate("/profile"))}>Refer a friend</button>
                    </div>

                    <div className='referralfirstinnerdiv'>
                        <img loading="lazy" style={{ height: "100%" }} src={require('../../images/referral.webp')} alt="Premsi.com referral bonus" />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ExplanationScreen

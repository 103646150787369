import React, { useState } from 'react'
import Footer from '../../../components/Footer'
import { useNavigate } from 'react-router-dom'
import './index.css'
import { Helmet } from 'react-helmet'
import NavForNotHome from '../../../components/Nav/NavForNotHome';

function CommunityGuidelines() {
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Premsi - Community Guidelines❤️" />
                <meta property="og:description" content="Premsi | These are the Community Guidelines of Premsi. . Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <meta property="og:url" content="https://premsi.com/communityguidelines" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/premsicom-accb2.appspot.com/o/P1-min.png?alt=media&token=1fbb167c-ed04-444c-b45c-dc411cdbb16f" />
                <meta property="og:site_name" content="Premsi - sell digital content" />
                <link rel="canonical" href="https://premsi.com/communityguidelines" />

                <meta name="description" content="Premsi | Community Guidelines - Premsi lets you easily sell and market your Videos/Pics/PDFs/Groups and soon courses. All for free and in 1 minute." />
                <title>Premsi - Community Guidelines</title>
            </Helmet>
            <NavForNotHome />
            <div className='outerlegaldiv' style={{ marginTop: 20 }}>
                <div className='innerlegaldiv'>
                    <h1>Community Guidelines - Premsi</h1>

                    <h2>Welcome to the Community Guidelines of PREMSI!</h2>

                    <p>Our guidelines are designed to foster a respectful and secure atmosphere for all PREMSI users. With a diverse global community, we kindly ask you to be mindful of various cultural norms when evaluating the appropriateness of content shared on PREMSI. </p>
                    <p>We celebrate creativity and self-expression while ensuring the safety of our members and preventing any unlawful activities on our platform. Should we identify any illicit content on PREMSI Social, appropriate actions will be taken, including content removal, temporary feature access limitations or account deactivation.</p>
                    <p>Please note that these guidelines provide general insights and aim to educate rather than restrict. For more comprehensive information, please refer to our Terms of Use. </p>

                    <p>If you come across behavior or content that violates our principles, please take a moment to report it so we can address the matter. </p>
                    <p>You can effortlessly do this via the PREMSI app, which is also the place to share feedback and feature requests. </p>
                    <p>Our dedicated human support team (no AI bots here!) attentively reviews every report we receive. To facilitate the review process, providing context such as explaining why you are reporting another PREMSI member, along with pertinent screenshots and details about previous interactions, will significantly assist us in taking appropriate actions. This will be done once a post has passed the threshold of enough reports. This threshold will be aligned with the amount of users we have.</p>

                    <p>Should you report content, we may reach out to you for more information to gain a comprehensive understanding of the issue. </p>
                    <p>At PREMSI, we retain the right to encourage a member to remove or voluntarily remove any content that might be inappropriate for the community, even if not explicitly outlined here. </p>
                    <p>We wholeheartedly appreciate your contribution to our community, making PREMSI a secure and expressive space for all. </p>


                    <h3>1. Respectful Interactions </h3>
                    <p>While we encourage user autonomy, we strictly prohibit any content or conduct aiming to harm or distress fellow PREMSI users. This encompasses any form of abusive content, directed toward specific individuals or involving unwanted communication. </p>

                    <h3>2. Bullying</h3>
                    <p>Notably, we recognize that bullying can be particularly detrimental to minors, and we have measures in place to safeguard them against online bullying. </p>

                    <h3>3. Engaging With Public Figures </h3>
                    <p>We support open discourse and critical discussions surrounding public figures. Nevertheless, we will remove content that contravenes our policies, including hate speech and threats, and does not contribute to constructive discourse. </p>

                    <h3>4. Sensitive Content </h3>
                    <p>On PREMSI sexually explicit material, including depictions of sexual acts MUST be marked as sensitive. We strictly forbid any content that sexualizes minors. </p>
                    <p>Educational content promoting sex education and reproductive rights is tolerated on our plattorm. </p>

                    <h3>5. Guidance For Mature Content </h3>
                    <p>We respect artistic freedom and understand that nudity can be a form of creative expression. Follow these rules to responsibly share mature content on PREMSI Social: </p>
                    <p>If your content features consensual nudity or sexual activity, provide ample context to help viewers understand its purpose and mark it as sensitive during the upload process. </p>
                    <p>We encourage open dialogue on sensitive topics, and we believe this guidance can help you express yourself within our plattorm's boundaries. </p>

                    <h3>6. Graphic Content </h3>
                    <p>Content portraying graphic violence, self-harm, or eating disorders has to be marked as sensitive. While we permit discussions surrounding these topics to raise awareness and provide support, we do not tolerate content that glorifies or encourages harm. </p>

                    <h3>7. Hate Speech </h3>
                    <p>We do not tolerate content promoting violence, humiliation, or mistreatment of individuals or groups based on attributes like race, ethnicity, gender, religion, and more. Exceptions are made for educational, satire, or comedy purposes, but clarity of intent is essential. </p>

                    <h3>8. Copyright And Trademark Compliance </h3>
                    <p>Respect intellectual property rights. </p>

                    <h3>9. Firearms And Drugs </h3>
                    <p>Content involving firearms and legal drugs must adhere to applicable laws and must be marked as sensitive during the upload process. We prohibit content that promotes violence, illegal acts, or drug distribution. Exceptions are made for educational purposes. </p>

                    <h3>10. Animal Welfare </h3>
                    <p>Content promoting animal cruelty or mistreatment is strictly prohibited. </p>

                    <h3>11. Sponsored Posts And Advertising </h3>
                    <p>Advertisements on PREMSI must adhere to relevant regulations and include proper disclosures. Users are responsible for compliant advertising. </p>

                    <h3>12. Threats </h3>
                    <p>Any credible threats of violence are prohibited and may result in law enforcement involvement. </p>

                    <h3>13. How To Report </h3>
                    <p>Access the "Report" option via the app's menus to report content or users. You can also contact support through the app or via email at error@premsi.com and please include the link of the post you are about to report. </p>

                    <h2 style={{ textAlign: "center", marginTop: 20, textDecoration: "underline" }}>We appreciate your commitment to the PREMSI community and your contribution to creating a secure and expressive digital space for everyone. </h2>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default CommunityGuidelines

import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, startAfter, updateDoc, where } from "firebase/firestore";
import { auth, db, functions, storage } from "../firebase";
import { submituser } from "../features/currentUser";
import { sendEmailVerification } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { uploadBytesResumable, getDownloadURL, ref } from 'firebase/storage';

export const checkIfUsernameExists = (username) => new Promise((resolve, reject) => {
    //console.log("inner checkIfUsernameExists")
    const q = query(collection(db, 'user'), where("displayName", "==", username), limit(1));

    const f = async () => {
        const docSnap = await getDocs(q);
        const user = [];
        //console.log("last visible", lastVisible);
        docSnap.forEach((doc) => {
            user.push([doc.id, doc.data()]);
        });
        resolve(user)
    }
    f()
})

export const getCurrentUserData = () => dispatch => {
    // here not onsnapshot but only get it once
    //console.log("in getCurrentUserData", auth.currentUser.uid)

    // just to get user once otherwise would change the whole time as followers incr4ease or sth else for user changes
    getDoc(doc(db, "user", auth.currentUser.uid)).then((user) => {
        //console.log("this is only once user", user.data())
        dispatch(submituser({ currentUser: user.data() }))
    })
}

export const updateSensitive = (uid, newsensitive) => new Promise((resolve, reject) => {
    //console.log("in updateSensitive")
    updateDoc(doc(db, 'user', uid), { "sensitive": newsensitive, "settedsensitive": serverTimestamp() }).then(() => {
        resolve()
    })
})

export const getCurrentBalanceOfUser = () => new Promise((resolve, reject) => {
    //console.log("in getCurrentBalance")
    getDoc(doc(db, "user", auth.currentUser.uid, "more", "finance")).then((financials) => {
        resolve(financials.data())
    })
})

export const doEmailVerification = () => new Promise((resolve, reject) => {
    // console.log("doEmailVerification")
    sendEmailVerification(auth.currentUser)
        .then(() => {
            const Refsec = doc(db, 'user', auth.currentUser.uid);
            updateDoc(Refsec, {
                "emailversent": serverTimestamp()
            }).then(() => {
                resolve("worked")
            })
        });
})


export const connectpaypaltouser = (pptoken) => new Promise((resolve, reject) => {
    //console.log("connectpaypaltouser", auth.currentUser.uid)
    async function us() {
        await auth.currentUser.getIdToken(true).then((tok) => {

            const connectpp = httpsCallable(functions, 'connectpp');
            connectpp({ user: auth.currentUser.uid, idtoken: tok, pptoken: pptoken })
                .then((result) => {
                    // Read result of the Cloud Function.
                    //console.log("resulttt connectpaypaltouser", result)
                    if (result.data === "success") {
                        resolve("success")
                    } else if (result.data === "errorverified") {
                        resolve("errorverified")
                    } else {
                        resolve("error")
                    }
                })
                .catch((error) => {
                    // Getting the Error details.
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    //console.log("errorcloudfunction", error)
                    resolve("error")
                });
        })
    }
    us()
})

export const getUserProfileInfoWithId = (userid) => new Promise((resolve, reject) => {

    //console.log("in getUserProfileInfoWithId gets user", userid)

    // just to get user once otherwise would change the whole time as followers incr4ease or sth else for user changes
    getDoc(doc(db, "user", userid)).then((user) => {
        if (user.exists()) {
            resolve(user.data())
        } else {
            resolve("usernotexists")
        }
    })
})

export const getUsersLastSixPosts = (userid, currentUser) => new Promise((resolve, reject) => {
    const Ref = collection(db, 'post');

    let q = query(Ref, where("creator", "==", userid), where("premium", "==", false), where("showpost", "==", true), where("sensitive", "==", false), orderBy('creationDate', 'desc'), limit(4));

    if (currentUser !== null && currentUser !== undefined && currentUser.sensitive === true) {
        q = query(Ref, where("creator", "==", userid), where("premium", "==", false), orderBy('creationDate', 'desc'), limit(4))
    }// can also implement here ,where("showpost","==",true)

    const f = async () => {
        const docSnap = await getDocs(q);
        const lastsixposts = [];
        docSnap.forEach((doc) => {
            lastsixposts.push([doc.id, doc.data()]);
        });
        resolve(lastsixposts)
    }
    f()
})

export const getPostWithId = (postid, currentUser) => new Promise((resolve, reject) => {
    //console.log("getPostWithId", postid, currentUser)
    // maybe this to improve by doing on backend and checking on backend if post and user are both premium
    getDoc(doc(db, "post", postid)).then((post) => {
        if (post.exists()) {
            if (post.data().premium === true && (currentUser === undefined || currentUser.premium === false || currentUser.premiumend < (new Date().getTime() / 1000))) {
                //console.log("getPostWithId post is premium but currentuser doesn't have")
                resolve("nopremium")
            } else {
                resolve([post.id, post.data()])
            }
        } else {
            resolve("postnotexists")
        }
    })
})

export const getUserInfoWithId = (userid) => new Promise((resolve, reject) => {

    //console.log("in getPostWithId gets user", postid)
    getDoc(doc(db, "user", userid)).then((user) => {
        if (user.exists()) {
            resolve(user.data())
        } else {
            resolve("usernotexists")
        }
    })
})

export const getUserInfoWithUsername = (username) => new Promise((resolve, reject) => {

    //console.log("getUserInfoWithUsername", username)
    const Ref = collection(db, 'user');

    let q = query(Ref, where("displayName", "==", username), limit(1))

    const f = async () => {
        const docSnap = await getDocs(q);
        if (docSnap.empty) {
            resolve("empty")
        } else {
            docSnap.forEach((doc) => {
                resolve([doc.id, doc.data()]);
            });
        }
    }
    f()
})

export const searchUsers = (username) => new Promise((resolve, reject) => {

    // console.log("in searchUsers gets user", txt)
    // search users like in app

    const q = query(collection(db, 'user'), where("displayName", ">=", username), where("displayName", "<=", username + '\uf8ff'), limit(5));
    const f = async () => {
        const docSnap = await getDocs(q);
        const users = [];
        const lastVisible = docSnap.docs[docSnap.docs.length - 1]
        //console.log("last visible", lastVisible);
        docSnap.forEach((doc) => {
            users.push([doc.id, doc.data()]);
        });
        users.push(lastVisible)
        resolve(users)
    }
    f()
})

export const searchMoreUsersFir = (username, lastVisible) => new Promise((resolve, reject) => {
    if (username === "") {
        resolve([])
    }
    // console.log("searchMoreUsers", lastVisible)
    const q = query(collection(db, 'user'), where("displayName", ">=", username), where("displayName", "<=", username + '\uf8ff'), startAfter(lastVisible), limit(5));
    const f = async () => {
        const docSnap = await getDocs(q);
        const users = [];
        const lastVisible = docSnap.docs[docSnap.docs.length - 1]
        //console.log("last visible", lastVisible);
        docSnap.forEach((doc) => {
            users.push([doc.id, doc.data()]);
        });
        users.push(lastVisible)
        resolve(users)
    }
    f()
})

// gere fetch the trending daily videos from firestore by searcing for day field and order by likesCount
//here watch for showpost === true
export const getDailyTrending = (currenUserObject) => new Promise((resolve, reject) => {

    //console.log("getDailyTrending", currenUserObject)
    const Ref = collection(db, 'post');
    let todaysdate = String(new Date().getDate()) + String(new Date().getMonth()) + String(new Date().getFullYear())
    // get current week of year
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil(days / 7);
    let todaysweek = String(weekNumber).trim() + String(new Date().getFullYear())

    let q = query(Ref, where("premium", "==", false), where("day", "==", todaysdate), where("showpost", "==", true), where("sensitive", "==", false), orderBy("likesCount", "desc"), limit(5))
    if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
        q = query(Ref, where("premium", "==", false), where("day", "==", todaysdate), where("showpost", "==", true), orderBy("likesCount", "desc"), limit(5))
    }

    let qtwo = query(Ref, where("premium", "==", false), where("week", "==", todaysweek), where("showpost", "==", true), where("sensitive", "==", false), orderBy("likesCount", "desc"), limit(5))
    if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
        qtwo = query(Ref, where("premium", "==", false), where("week", "==", todaysweek), where("showpost", "==", true), orderBy("likesCount", "desc"), limit(5))
    }

    const f = async () => {
        const docSnap = await getDocs(q);
        //console.log("get trending posts on web")

        const posts = [];
        docSnap.forEach((doc) => {
            if (currenUserObject !== null && currenUserObject !== undefined) {
                if (currenUserObject.gemeldeteposts.includes(doc.id) || currenUserObject.gemeldeteusers.includes(doc.data().creator)) {
                    //console.log("this one is gemeldet")
                } else {
                    posts.push([doc.id, doc.data()]);
                }
            } else {
                posts.push([doc.id, doc.data()]);
            }
        });
        // following line sorts the posts by their popularity which shows the user first posts with highest popularity
        //console.log("fjdfhg", posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1))
        //posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1)
        //console.log("This is trending posts", todaysweek, posts.length)
        if (posts.length === 0) {
            const docSnaptwo = await getDocs(qtwo);
            //console.log("get trending posts on web")
            const poststwo = [];
            docSnaptwo.forEach((doc) => {
                if (currenUserObject !== null && currenUserObject !== undefined) {
                    if (currenUserObject.gemeldeteposts.includes(doc.id) || currenUserObject.gemeldeteusers.includes(doc.data().creator)) {
                        //console.log("this one is gemeldet")
                    } else {
                        poststwo.push([doc.id, doc.data()]);
                    }
                } else {
                    poststwo.push([doc.id, doc.data()]);
                }
            });
            resolve(poststwo)
        } else {
            resolve(posts)
        }
    }
    f()
})

// gere fetch the posts from firestore
//here watch for showpost === true
export const getFeed = (currenUserObject) => new Promise((resolve, reject) => {

    //console.log(currenUserObject)
    const objpos = Math.floor(Math.random() * 3) + 1
    const randomnr = Math.floor(Math.random() * 10000000) + 1
    //console.log(objpos, randomnr)
    const Ref = collection(db, 'post');

    let q = query(Ref, where(`random.${objpos}`, ">=", randomnr), where("premium", "==", false), where("showpost", "==", true), where("sensitive", "==", false), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(5))
    //     let q = query(Ref, where(`random.${objpos}`, ">=", randomnr), where("premium", "==", false), where("showpost", "==", true), where("sensitive", "==", false),orderBy(`random.${objpos}`, "desc"),orderBy("creationDate", "desc"), limit(5))

    if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
        q = query(Ref, where(`random.${objpos}`, ">=", randomnr), where("showpost", "==", true), where("premium", "==", false), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(5))
    }

    const f = async () => {
        const docSnap = await getDocs(q);

        if (docSnap.docs.length === 0) {
            //console.log("changed")

            let qtwo = query(Ref, where(`random.${objpos}`, "<=", randomnr), where("premium", "==", false), where("sensitive", "==", false), where("showpost", "==", true), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(5))
            if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
                qtwo = query(Ref, where(`random.${objpos}`, "<=", randomnr), where("premium", "==", false), where("showpost", "==", true), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(5))
            }

            const docSnaptwo = await getDocs(qtwo);
            const posts = [];
            docSnaptwo.forEach((doc) => {
                if (currenUserObject !== null && currenUserObject !== undefined) {
                    if (currenUserObject.gemeldeteposts.includes(doc.id) || currenUserObject.gemeldeteusers.includes(doc.data().creator)) {
                        // console.log("this one is gemeldet")
                    } else {
                        posts.push([doc.id, doc.data()]);
                    }
                } else {
                    posts.push([doc.id, doc.data()]);
                }
            });
            //console.log("fjdfhg", posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1))
            posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1)
            resolve(posts)
        } else {
            //console.log("normal")

            const posts = [];
            docSnap.forEach((doc) => {
                if (currenUserObject !== null && currenUserObject !== undefined) {
                    if (currenUserObject.gemeldeteposts.includes(doc.id) || currenUserObject.gemeldeteusers.includes(doc.data().creator)) {
                        //console.log("this one is gemeldet")
                    } else {
                        posts.push([doc.id, doc.data()]);
                    }
                } else {
                    posts.push([doc.id, doc.data()]);
                }
            });
            // following line sorts the posts by their popularity which shows the user first posts with highest popularity
            //console.log("fjdfhg", posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1))
            posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1)
            resolve(posts)
        }
    }
    f()
})


// here fetch premium the posts from firestore
//here watch for showpost === true
export const getPremiumFeed = (currenUserObject) => new Promise((resolve, reject) => {

    const objpos = Math.floor(Math.random() * 3) + 1
    const randomnr = Math.floor(Math.random() * 10000000) + 1

    const Ref = collection(db, 'post');

    let q = query(Ref, where(`random.${objpos}`, ">=", randomnr), where("premium", "==", true), where("showpost", "==", true), where("sensitive", "==", false), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(5))
    if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
        q = query(Ref, where(`random.${objpos}`, ">=", randomnr), where("premium", "==", true), where("showpost", "==", true), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(5))
    }

    const f = async () => {
        const docSnap = await getDocs(q);
        if (docSnap.docs.length === 0) {
            //console.log("changed")

            let qtwo = query(Ref, where(`random.${objpos}`, "<=", randomnr), where("premium", "==", true), where("showpost", "==", true), where("sensitive", "==", false), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(5))
            if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
                qtwo = query(Ref, where(`random.${objpos}`, "<=", randomnr), where("premium", "==", true), where("showpost", "==", true), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(5))
            }

            const docSnaptwo = await getDocs(qtwo);
            const posts = [];
            docSnaptwo.forEach((doc) => {
                if (currenUserObject !== null && currenUserObject !== undefined) {
                    if (currenUserObject.gemeldeteposts.includes(doc.id) || currenUserObject.gemeldeteusers.includes(doc.data().creator)) {
                        //console.log("this one is gemeldet")
                    } else {
                        posts.push([doc.id, doc.data()]);
                    }
                } else {
                    posts.push([doc.id, doc.data()]);
                }
            });
            posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1)
            resolve(posts)
        } else {
            //console.log("normal")

            const posts = [];
            docSnap.forEach((doc) => {
                if (currenUserObject !== null && currenUserObject !== undefined) {
                    if (currenUserObject.gemeldeteposts.includes(doc.id) || currenUserObject.gemeldeteusers.includes(doc.data().creator)) {
                        // console.log("this one is gemeldet")
                    } else {
                        posts.push([doc.id, doc.data()]);
                    }
                } else {
                    posts.push([doc.id, doc.data()]);
                }
            });
            posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1)
            resolve(posts)
        }
    }
    f()
})


// gere fetch the trending top one daily videos from firestore by searcing for day field and order by likesCount
//here watch for showpost === true
export const getDailyTopOneTrending = (currenUserObject) => new Promise((resolve, reject) => {

    //console.log("getDailyTrending", currenUserObject)
    const Ref = collection(db, 'post');
    let todaysdate = String(new Date().getDate()) + String(new Date().getMonth()) + String(new Date().getFullYear())
    // where("day","==",todaysdate)
    let q = query(Ref, where("premium", "==", false), where("day", "==", todaysdate), where("showpost", "==", true), where("sensitive", "==", false), orderBy("likesCount", "desc"), limit(1))
    if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
        q = query(Ref, where("premium", "==", false), where("day", "==", todaysdate), where("showpost", "==", true), orderBy("likesCount", "desc"), limit(1))
    }

    const f = async () => {
        const docSnap = await getDocs(q);
        // console.log("get toptrendingpost on web", docSnap.empty)

        if (docSnap.empty) {
            resolve("empty")
        } else {
            docSnap.forEach((doc) => {
                //console.log("this is toptrendingpsot", doc.data())
                resolve([doc.id, doc.data()]);
            });
        }

    }
    f()
})

// gere fetch the posts from firestore
//here watch for showpost === true
export const getOneRandomPost = (currenUserObject) => new Promise((resolve, reject) => {

    //console.log(currenUserObject)
    const objpos = Math.floor(Math.random() * 3) + 1
    const randomnr = Math.floor(Math.random() * 10000000) + 1
    //console.log(objpos, randomnr)
    const Ref = collection(db, 'post');

    let q = query(Ref, where(`random.${objpos}`, ">=", randomnr), where("premium", "==", false), where("showpost", "==", true), where("sensitive", "==", false), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(1))
    //     let q = query(Ref, where(`random.${objpos}`, ">=", randomnr), where("premium", "==", false), where("showpost", "==", true), where("sensitive", "==", false),orderBy(`random.${objpos}`, "desc"),orderBy("creationDate", "desc"), limit(5))

    if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
        q = query(Ref, where(`random.${objpos}`, ">=", randomnr), where("showpost", "==", true), where("premium", "==", false), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(1))
    }

    const f = async () => {
        const docSnap = await getDocs(q);

        if (docSnap.docs.length === 0) {
            // console.log("changed")

            let qtwo = query(Ref, where(`random.${objpos}`, "<=", randomnr), where("premium", "==", false), where("sensitive", "==", false), where("showpost", "==", true), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(1))
            if (currenUserObject !== null && currenUserObject !== undefined && currenUserObject.sensitive === true) {
                qtwo = query(Ref, where(`random.${objpos}`, "<=", randomnr), where("premium", "==", false), where("showpost", "==", true), orderBy(`random.${objpos}`, "asc"), orderBy("popularity", "desc"), limit(1))
            }

            const docSnaptwo = await getDocs(qtwo);
            docSnaptwo.forEach((doc) => {
                if (currenUserObject !== null && currenUserObject !== undefined) {
                    if (currenUserObject.gemeldeteposts.includes(doc.id) || currenUserObject.gemeldeteusers.includes(doc.data().creator)) {
                        // console.log("this one is gemeldet")
                    } else {
                        resolve([doc.id, doc.data()]);
                    }
                } else {
                    resolve([doc.id, doc.data()]);
                }
            });
            //console.log("fjdfhg", posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1))
        } else {
            //console.log("normal")

            docSnap.forEach((doc) => {
                if (currenUserObject !== null && currenUserObject !== undefined) {
                    if (currenUserObject.gemeldeteposts.includes(doc.id) || currenUserObject.gemeldeteusers.includes(doc.data().creator)) {
                        //console.log("this one is gemeldet")
                    } else {
                        resolve([doc.id, doc.data()]);
                    }
                } else {
                    resolve([doc.id, doc.data()]);
                }
            });
            // following line sorts the posts by their popularity which shows the user first posts with highest popularity
            //console.log("fjdfhg", posts.sort((a, b) => (a[1].popularity < b[1].popularity) ? 1 : -1))
        }
    }
    f()
})


export const getGroupById = (groupid) => new Promise(async (resolve, reject) => {

    const docSnap = await getDoc(doc(db, "groups", groupid));
    if (docSnap.exists()) {
        //console.log("Document data:", docSnap.exists());
        //console.log(docSnap.id, "exists")
        resolve([docSnap.id, docSnap.data()]);
    } else {
        resolve("groupnotexists")
    }
})

export const getPremiumMessageById = (pmid) => new Promise(async (resolve, reject) => {

    const docSnap = await getDoc(doc(db, "premiummessage", pmid));
    if (docSnap.exists()) {
        //console.log("Document data:", docSnap.exists());
        //console.log(docSnap.id, "exists")
        resolve([docSnap.id, docSnap.data()]);
    } else {
        resolve("pmnotexists")
    }
})

export const getGroupsByUserId = (uid = auth.currentUser.uid, currentUserObject) => new Promise((resolve, reject) => {
    const Ref = collection(db, 'groups');
    //console.log("currentuserobject in getGroupsByUserId", currentUserObject)

    let q = query(Ref, where("creator", "==", uid), where("active", "==", true), where("sensitive", "==", false), orderBy('creationDate', 'desc'), limit(5));
    if (currentUserObject !== null && uid === auth.currentUser.uid || currentUserObject !== null && currentUserObject.sensitive === true) {
        q = query(Ref, where("creator", "==", uid), where("active", "==", true), orderBy('creationDate', 'desc'), limit(5))
    }

    const f = async () => {
        const docSnap = await getDocs(q);
        const groups = [];
        docSnap.forEach((doc) => {
            //posts.push([doc.id, doc.data()]);
            if (currentUserObject === null || currentUserObject === undefined) {
                groups.push([doc.id, doc.data()]);
            } else {
                if (currentUserObject.gemeldeteposts.includes(doc.id)) {
                    //console.log("this one is gemeldet")
                } else {
                    groups.push([doc.id, doc.data()]);
                }
            }
        });
        if (groups.length === 0) {
            resolve("empty")
        } else {
            resolve(groups)
        }
    }
    f()
})

export const getPremiumMessagesOfUser = (userid, type) => new Promise((resolve, reject) => {

    const q = query(collection(db, 'premiummessage'), where("creator", "==", userid), where("type", "==", type), where("active", "==", true), orderBy("creationDate", 'desc'), limit(5));

    const f = async () => {
        const docSnap = await getDocs(q);
        const premiummessages = [];
        //console.log("last visible", lastVisible);
        docSnap.forEach((doc) => {
            premiummessages.push([doc.id, doc.data()]);
        });
        resolve(premiummessages)
    }
    f()
})

export const searchGroupsAlgolia = (search, userobject, page) => new Promise((resolve, reject) => {

    const AlgoliaResults = httpsCallable(functions, 'searchalgolia');
    AlgoliaResults({ query: search, user: userobject, page: page })
        .then((result) => {
            // Read result of the Cloud Function.
            //console.log("resulttt AlgoliaResults", result.data.res)
            resolve(result.data.res)
        })
        .catch((error) => {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            const details = error.details;
            //console.log("errorcloudfunction", error)
            resolve("error")
        });

})

export const searchPremiumMessagesAlgolia = (search, userobject, page) => new Promise((resolve, reject) => {

    const AlgoliaResults = httpsCallable(functions, 'searchalgoliapremiummessages');
    AlgoliaResults({ query: search, user: userobject, page: page })
        .then((result) => {
            // Read result of the Cloud Function.
            //console.log("resulttt AlgoliaResults", result.data.res)
            resolve(result.data.res)
        })
        .catch((error) => {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            const details = error.details;
            //console.log("errorcloudfunction", error)
            resolve("error")
        });

})


// move everything where balance used into subcomponent
export let unsubFinancials;
export const getCurrentUserFinancials = (setfinancials) => new Promise((resolve, reject) => {
    unsubFinancials = onSnapshot(doc(db, "user", auth.currentUser.uid, "more", "finance"), (doc) => {
        //console.log("getCurrentUserFinancialsfire", doc.data())
        setfinancials(doc.data())
    });
})


export const boughtPremiumMessage = (uid, premiummessage, id) => new Promise((resolve, reject) => {
    // console.log(uid, premiummessage)
    auth.currentUser.getIdToken(true).then((tok) => {
        const boughtPremiumMessageFunc = httpsCallable(functions, 'boughtPremiumMessage');
        boughtPremiumMessageFunc({ premiummessage: JSON.stringify(premiummessage), postid: id, idtoken: tok, user: auth.currentUser.uid })
            .then((result) => {
                if (result.data === "success") {
                    resolve("success")
                } else {
                    resolve("error")
                }
            })
            .catch((error) => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                //console.log("errorcloudfunction", error)
                resolve("error")
            });
    }).catch(() => {
        resolve("error")
    })
})


export const saveMediaToStorage = async (media, path) => new Promise((resolve, reject) => {
    const fileRef = ref(storage, path);
    //console.log("trying upload to storage", media)
    fetch(media)
        .then(response => response.blob())
        .then(blob => {

            const uploadTask = uploadBytesResumable(fileRef, blob);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // look https://firebase.google.com/docs/storage/web/upload-files what could do here
                    //console.log("uploading", snapshot.metadata)
                },
                (error) => {
                    // Handle unsuccessful uploads
                    // console.log(error)
                    // console.log("error uploading to storage")
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL)
                    });
                }
            );
        })
        .catch((e) => {
            // console.log(e)
            reject()
        })
})

export const createPremiumMessageFB = (description, media, sensitivebool, type, price, currentUser, pdftitle, amountfiles) => dispatch => new Promise((resolve, reject) => {
    //  console.log("creating now a post") (caption, imagelinks, sensitive, "image", price, currentUser, "nopdf", amountfiles)

    let todaysdate = String(new Date().getDate()) + String(new Date().getMonth()) + String(new Date().getFullYear())
    let todaysmonth = String(new Date().getMonth()) + String(new Date().getFullYear())
    // get current week of year
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil(days / 7);

    let todaysweek = String(weekNumber).trim() + String(new Date().getFullYear())

    if (type === "pdf") {
        const Ref = collection(db, 'premiummessage'); //with collection an id ill be generated with doc id is know such as userid
        addDoc(Ref, {
            creator: auth.currentUser.uid,
            creatordisplayname: currentUser.displayName,
            verifieduser: currentUser.verified === undefined || currentUser.verified === null ? false : currentUser.verified,
            media,
            description: description,
            bought: 0,
            reported: 0,
            title: pdftitle,
            price,
            uploadmonth: todaysmonth,
            uploadweek: todaysweek,
            uploadday: todaysdate,
            sensitive: sensitivebool,
            type,
            active: true,
            "reportsCount": 0,
            creationDate: serverTimestamp()
        })
            .then((docRef) => resolve(docRef.id))
            .catch(() => reject())
    } else {
        const Ref = collection(db, 'premiummessage'); //with collection an id ill be generated with doc id is know such as userid
        addDoc(Ref, {
            creator: auth.currentUser.uid,
            creatordisplayname: currentUser.displayName,
            verifieduser: currentUser.verified === undefined || currentUser.verified === null ? false : currentUser.verified,
            media,
            description: description,
            bought: 0,
            reported: 0,
            amountfiles: amountfiles,
            price,
            uploadmonth: todaysmonth,
            uploadweek: todaysweek,
            uploadday: todaysdate,
            sensitive: sensitivebool,
            type,
            active: true,
            "reportsCount": 0,
            creationDate: serverTimestamp()
        })
            .then((docRef) => resolve(docRef.id))
            .catch(() => reject())
    }
})


export const createGroup = (description, med, sensitivebool, price, currentUser, grouptitle, isCheckedonetimefee) => new Promise((resolve, reject) => {
    //console.log("creating now a post", description, med, sensitivebool, price, currentUser)

    let todaysdate = String(new Date().getDate()) + String(new Date().getMonth()) + String(new Date().getFullYear())
    let todaysmonth = String(new Date().getMonth()) + String(new Date().getFullYear())
    // get current week of year
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil(days / 7);

    let todaysweek = String(weekNumber).trim() + String(new Date().getFullYear())

    const Ref = collection(db, 'groups'); //with collection an id ill be generated with doc id is know such as userid
    addDoc(Ref, {
        creator: auth.currentUser.uid,
        creatordisplayname: currentUser.displayName,
        media: med,
        grouptitle: grouptitle,
        description: description,
        bought: 0,
        price: price,
        earned: 0,
        writer: [currentUser.displayName],
        uploadmonth: todaysmonth,
        uploadweek: todaysweek,
        uploadday: todaysdate,
        sensitive: sensitivebool,
        onetimefee: isCheckedonetimefee,
        titlesetted: serverTimestamp(),
        descriptionsetted: serverTimestamp(),
        sensitivesetted: 0,
        active: true,
        lastMessage: "Hii, I just created this group 🎉",
        membersCount: 0,
        lastUpdate: serverTimestamp(),
        messagesCount: 1,
        "reportsCount": 0,
        creationDate: serverTimestamp()
    })
        .then((docRef) => resolve(docRef.id))
        .catch((e) => (/*console.log("errorrr", e),*/ reject()))
})